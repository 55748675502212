/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
// import find from 'lodash/find';
// import sum from 'lodash/sum';
import { connect } from 'react-redux'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import { updateWorkflowField } from '@e2grnd/ui-core/dist/contexts/redux/actions'
import withConfig from '@e2grnd/ui-core/dist/contexts/withConfig'
import { getColumnFrom2DArray } from '@e2grnd/ui-core/dist/core/lib'

// import { getColumnFrom2DArray } from '@e2grnd/ui-core/dist/core/lib';
import { checkStep2TableDuplicate, checkStep2TableEmpty, checkStep2TableEmpty2, getReplacements } from '../lib/checkTables.js'

class Step2 extends Component {
  static propTypes = {
    problemType: PropTypes.string,
    cmlDataAndLabelsTable: PropTypes.array,
    cmlDataAndLabelsTable2: PropTypes.array,
    cmlDataAndLabelsTable3: PropTypes.array,
    acfInputTable: PropTypes.array,
    dispatch: PropTypes.func,
    config: PropTypes.object,
    // cmlReplacementDatesTable: PropTypes.array,
    // config: PropTypes.object,
  }

  componentDidUpdate(prevProps) {
    const { cmlDataAndLabelsTable2, dispatch } = this.props
    if(!isEqual(cmlDataAndLabelsTable2, prevProps.cmlDataAndLabelsTable2)) {
      dispatch(updateWorkflowField({
        name: 'cmlDataAndLabelsTable2',
        value: getReplacements(cmlDataAndLabelsTable2),
      }))
    }
  }

  render() {
    const {
      problemType,
      cmlDataAndLabelsTable,
      cmlDataAndLabelsTable2,
      cmlDataAndLabelsTable3,
      acfInputTable,
      config,
      // cmlReplacementDatesTable,
      // config,
    } = this.props
    let step1StatusEmpty = true
    let step1StatusDuplicate = false
    // let step2ReplacementStatus = false;
    // let expectedSum = 0;
    let tableData
    let col0 = []
    if(problemType === 'R') {
      tableData = find(config.dataTables, ['name', 'cmlDataAndLabelsTable3'])
      col0 = getColumnFrom2DArray(acfInputTable, 0)
    }
    const acfs = []
    if(problemType === 'R') {
      col0.forEach((acf) => {
        if(!acfs.includes(acf)) {
          if(acf !== null && acf !== '' && typeof acf !== 'undefined') {
            acfs.push(acf)
          }
        }
      })
      // eslint-disable-next-line no-param-reassign
      tableData.options.columns[2].source = acfs
    }
    if(typeof problemType !== 'undefined' && (problemType !== 'SIMP' && problemType !== 'R')) {
      step1StatusEmpty = checkStep2TableEmpty(cmlDataAndLabelsTable)
      step1StatusDuplicate = checkStep2TableDuplicate(cmlDataAndLabelsTable)
    }
    if(typeof problemType !== 'undefined' && problemType === 'SIMP') {
      step1StatusEmpty = checkStep2TableEmpty2(cmlDataAndLabelsTable2)
      step1StatusDuplicate = checkStep2TableDuplicate(cmlDataAndLabelsTable2)
    }
    if(typeof problemType !== 'undefined' && problemType === 'R') {
      step1StatusEmpty = checkStep2TableEmpty2(cmlDataAndLabelsTable3)
      step1StatusDuplicate = checkStep2TableDuplicate(cmlDataAndLabelsTable3)
    }
    return (
      <div className="fl w-100">
        <div>
          <p><strong>A separate row must be defined for each CML-ID in the circuit. All required columns are indicated with a * in the column header. Table data may be entered directly or copied/pasted from excel. When data is entered into the final row, an additional row is automatically provided. The CML ID and Flow Order Index columns must have unique values.</strong></p>
          {problemType !== 'SIMP' && problemType !== 'R'
            ? (
              <div>
                <p>The nps and pipe schedule are used as lookup values in the user configurables tables specified in the advanced options tab. The component type is used to determine component specific error multipliers.</p>
                <DataTableField id="cmlDataAndLabelsTable" name="cmlDataAndLabelsTable" value={cmlDataAndLabelsTable} startRows={4} colHeaders={{ USCustomary: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Pipe NPS<br />(Select)*', 'Pipe Sch<br />(Select)*', 'Nominal<br />Thickness (in)*', 'Base Date<br />(MM-DD-YY)*', 'Component Type<br />(Select)*', 'Tmin (in)*'], Metric: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Pipe NPS<br />(Select)*', 'Pipe Sch<br />(Select)*', 'Nominal<br />Thickness (mm)*', 'Base Date<br />(MM-DD-YY)*', 'Component Type<br />(Select)*', 'Tmin (in)*'] }} rowHeaders={false} />
              </div>
            )
            : ''}
          {problemType === 'SIMP'
            ? (
              <div>
                <p>The nominal and undertolerance are used to determine the initial metal loss.</p>
                <p>The number of replacements is used to reinitialize the base date for measurements after each replacement. If any CMLs have component replacements, then an additional table will appear below to enter the dates for each replacement.</p>
                <DataTableField id="cmlDataAndLabelsTable2" name="cmlDataAndLabelsTable2" value={getReplacements(cmlDataAndLabelsTable2)} startRows={4} colHeaders={{ USCustomary: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Nominal<br />Thickness (in)*', 'Undertolerance (%)*', 'Base Date<br />(MM-DD-YY)*', 'Number of<br />Replacements*', 'Replacement Dates<br />(comma-separated)*', 'Tmin (in)*'], Metric: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Nominal<br />Thickness (mm)*', 'Undertolerance (%)*', 'Base Date<br />(MM-DD-YY)*', 'Number of<br />Replacements*', 'Replacement Dates<br />(comma-separated)*', 'Tmin (in)*'] }} rowHeaders={false} />
                {/* {expectedSum > 0 ? (
                <DataTableField id="cmlReplacementDatesTable" name="cmlReplacementDatesTable" value={cmlReplacementDatesTable} startRows={2} colHeaders={{ USCustomary: ['CML ID*', 'Replacement Date<br />(MM-DD-YYYY)*'], Metric: ['CML ID*', 'Replacement Date<br />(MM-DD-YYYY)*'] }} rowHeaders={false} />
              ) : '' */}
                {/* } */}
              </div>
            )
            : ''}
          {problemType === 'R'
            ? (
              <div>
                <DataTableField id="acfInputTable" name="acfInputTable" value={acfInputTable} startRows={4} colHeaders={{ USCustomary: ['ACF Group Name', 'Mean Corrosion Rate (mil/yr)', 'Max Corrosion Rate (mil/yr)', 'Corrosion Rate SD (mil/yr)'], Metric: ['ACF Group Name', 'Mean Corrosion Rate (mm/yr)', 'Max Corrosion Rate (mm/yr)', 'Corrosion Rate SD (mm/yr)'] }} rowHeaders={false} />
                <p>The nps and pipe schedule are used as lookup values in the user configurables tables specified in the advanced options tab. The component type is used to determine component specific error multipliers.</p>
                <DataTableField id="cmlDataAndLabelsTable3" name="cmlDataAndLabelsTable3" value={cmlDataAndLabelsTable3} startRows={4} colHeaders={{ USCustomary: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Pipe NPS<br />(Select)*', 'Pipe Sch<br />(Select)*', 'Nominal<br />Thickness (in)*', 'Base Date<br />(MM/DD/YY)*', 'Component Type<br />(Select)*', 'Tmin (in)*', 'Monitored Component*'], Metric: ['CML ID*', 'Flow Order<br />Index*', 'ACF Group<br />Name*', 'Pipe NPS<br />(Select)*', 'Pipe Sch<br />(Select)*', 'Nominal<br />Thickness (mm)*', 'Base Date<br />(MM-DD-YY)*', 'Component Type<br />(Select)*', 'Tmin (in)*', 'Monitored Component*'] }} rowHeaders={false} />
              </div>
            )
            : ''}
          { step1StatusEmpty === false
            ? (
              <div>
                {step1StatusDuplicate === true
                  ? (
                    <div className="pa3">
                      <font color="red">CML table is invalid. Duplicate CML-IDs and Flow Order indices are not allowed.</font>
                      <Submit label="Next" disabled />
                    </div>
                  )
                  : <div>
                    {/* {problemType !== 'CS' && expectedSum > 0 ? (
                    <div>
                      {step2ReplacementStatus === true ? (
                        <div className="pa3">
                          <font color="red">Replacement table is invalid. Please add required column data for each replacement. The total number of replacements must match the total number specified in the Step 2 CML table.</font>
                          <Submit label="Next" disabled />
                        </div>
                      ) :
                        <Submit label="Next" />
                      }
                    </div>
                  ) : */}
                    <Submit label="Next" />
                    {/* } */}
                  </div>}
              </div>
            )
            : <div className="pa3">
              <font color="red">CML table is invalid. Please add required column data for each specified CML-ID.</font>
              <Submit label="Next" disabled />
            </div>}
        </div>
        <div className="f6 mt4">* indicates required columns</div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  cmlDataAndLabelsTable: state.workflow.fields.cmlDataAndLabelsTable,
  cmlDataAndLabelsTable2: state.workflow.fields.cmlDataAndLabelsTable2,
  cmlDataAndLabelsTable3: state.workflow.fields.cmlDataAndLabelsTable3,
  acfInputTable: state.workflow.fields.acfInputTable,
  // cmlReplacementDatesTable: state.workflow.fields.cmlReplacementDatesTable,
})

export default connect(mapStateToProps, null)(
  withConfig(Step2)
)
