import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const Anomalies = () => {
  // Use useSelector to access Redux state
  const circuit = useSelector((state) => state.workflow.fields.circuit)
  const allAnoms = useSelector((state) =>
    state.workflow.response.results.dataTables
      ? state.workflow.response.results.dataTables.allAnoms
      : undefined
  )

  // Render based on the presence of data
  if(!allAnoms || allAnoms.keywords[0].data.length === 0) {
    return <div>No Data Available</div>
  }

  return (
    <div className="cf w-100">
      <b>
        <i>{circuit}</i> All Anomalies
      </b>
      <DataTableDisplay id="allAnoms" table={allAnoms} containerHeight="200px" />
    </div>
  )
}

// PropTypes validation for clarity and development safety
Anomalies.propTypes = {
  circuit: PropTypes.string,
  allAnoms: PropTypes.object,
}

export default Anomalies
