// export function getThicknessProjectionGraphs(jsonData, key) {
//   const jsonObject = JSON.parse(jsonData[0]);
//   const graphs = {};
//   const traces = [];
//   const traceDataUt = {
//     x: jsonObject[key].data.utTime[0],
//     y: jsonObject[key].data.utThick[0],
//     mode: 'markers',
//     type: 'scatter',
//     name: 'UT Data',
//   };
//   traces.push(traceDataUt);
//   const traceDataRt = {
//     x: jsonObject[key].data.rtTime[0],
//     y: jsonObject[key].data.rtThick[0],
//     mode: 'markers',
//     type: 'scatter',
//     name: 'RT Data',
//   };
//   traces.push(traceDataRt);
//   const traceDataOther = {
//     x: jsonObject[key].data.otherTime[0],
//     y: jsonObject[key].data.otherThick[0],
//     mode: 'markers',
//     type: 'scatter',
//     name: 'Other Data',
//   };
//   traces.push(traceDataOther);
//   const traceFifty = {
//     x: jsonObject[key].time,
//     y: jsonObject[key].median,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Median',
//     xaxis: 'x2',
//     yaxis: 'y2',
//   };
//   traces.push(traceFifty);
//   const traceUpper = {
//     x: jsonObject[key].time,
//     y: jsonObject[key].upperBound,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Upper Bound',
//     xaxis: 'x3',
//     yaxis: 'y3',
//   };
//   traces.push(traceUpper);
//   const traceLower = {
//     x: jsonObject[key].time,
//     y: jsonObject[key].lowerBound,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Lower Bound',
//     xaxis: 'x4',
//     yaxis: 'y4',
//   };
//   traces.push(traceLower);
//   const graphId = `Thicknessgraph${key}`;
//   graphs[graphId] = {
//     traces,
//     layout: {
//       name: `thicknessProjection${key}`,
//       title: `Thickness Projection for CML${key}`,
//       xaxis: {
//         title: 'Time (yr)',
//       },
//       yaxis: {
//         title: 'Thickness (in)',
//         rangemode: 'tozero',
//       },
//       grid: {
//         rows: 2,
//         columns: 2,
//         pattern: 'independent',
//       },
//     },
//   };
//   return graphs;
// }
// export function getDensityPlotInputs() {
//   const densityInputs = [];
//   return densityInputs;
// }
// export function getResidualPlotInputs() {
//   const residualInputs = [];
//   return residualInputs;
// }

export function getPlotInputs(jsonData, nextTaDate, turnaroundFrequency, analysisDate) {
  const thicknessInputs = []
  const startIndices = []
  const endIndices = []
  let inputDates
  if(typeof jsonData !== 'undefined') {
    let newNextTaDate
    if(typeof nextTaDate === 'undefined') {
      newNextTaDate = '01/01/2024'
    } else {
      newNextTaDate = nextTaDate
    }
    let newTurnaroundFrequency
    if(typeof turnaroundFrequency === 'undefined') {
      newTurnaroundFrequency = 4
    } else {
      newTurnaroundFrequency = turnaroundFrequency
    }
    inputDates = [analysisDate, newNextTaDate, newTurnaroundFrequency]
    const jsonObject = JSON.parse(jsonData)
    const keys = Object.keys(jsonObject)
    const lengthKeys = keys.length
    let gridSize = 1
    if(lengthKeys >= 9) {
      gridSize = 9 // rows = 3, columns = 3
    } else if(lengthKeys >= 6) {
      gridSize = 6 // rows = 2, columns = 3
    } else if(lengthKeys >= 4) {
      gridSize = 4 // rows = 2, columns = 2
    } else if(lengthKeys >= 3) {
      gridSize = 3 // rows = 1, columns = 3
    } else if(lengthKeys >= 2) {
      gridSize = 2 // rows = 1, columns = 2
    }
    const numPlots = Math.ceil(lengthKeys / gridSize)
    for(let i = 0; i < numPlots; i += 1) {
      if(i < numPlots - 1) {
        const startIndex = i * gridSize
        const endIndex = (i + 1) * gridSize - 1
        startIndices.push(startIndex)
        endIndices.push(endIndex)
      } else {
        const lengthNewKeys = lengthKeys - gridSize * (i + 1)
        let newGridSize = 1
        if(lengthNewKeys >= 9) {
          newGridSize = 9 // rows = 3, columns = 3
        } else if(lengthNewKeys >= 6) {
          newGridSize = 6 // rows = 2, columns = 3
        } else if(lengthNewKeys >= 4) {
          newGridSize = 4 // rows = 2, columns = 2
        } else if(lengthNewKeys >= 3) {
          newGridSize = 3 // rows = 1, columns = 3
        } else if(lengthNewKeys >= 2) {
          newGridSize = 2 // rows = 1, columns = 2
        }
        const numPlotsNew = Math.ceil(lengthNewKeys / newGridSize)
        if(numPlotsNew > 0) {
          for(let j = 0; j < numPlotsNew; j += 1) {
            if(j < numPlots - 1) {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = i * gridSize + (j + 1) * newGridSize - 1
              startIndices.push(startIndex)
              endIndices.push(endIndex)
            } else {
              const startIndex = i * gridSize + j * newGridSize
              const endIndex = lengthKeys - 1
              startIndices.push(startIndex)
              endIndices.push(endIndex)
            }
          }
        } else {
          const startIndex = i * gridSize
          const endIndex = lengthKeys - 1
          startIndices.push(startIndex)
          endIndices.push(endIndex)
        }
      }
    }
    startIndices.forEach((start, index) => {
      thicknessInputs.push([start, endIndices[index], inputDates])
    })
  }
  return thicknessInputs
}

export function getPofGraphs2(jsonData, startIndex, endIndex, dates) {
  const jsonObject = JSON.parse(jsonData[0])
  const startKey = parseInt(startIndex, 10)
  const endKey = parseInt(endIndex, 10)
  const graphSize = endKey - startKey + 1
  const analysisDate = dates[0]
  const nextTaDate = dates[1]
  const taFrequency = dates[2]
  const taDateString = nextTaDate.split('/')
  const taDate = `${taDateString[2]}-${taDateString[0]}-${taDateString[1]}`
  const twoTaDate = `${parseInt(taDateString[2], 10) + taFrequency}-${taDateString[0]}-${taDateString[1]}`
  let rows = 1
  let columns = 1
  if(graphSize > 6) {
    rows = 3
    columns = 3
  } else if(graphSize === 6) {
    rows = 2
    columns = 3
  } else if(graphSize === 5) {
    rows = 2
    columns = 3
  } else if(graphSize === 4) {
    rows = 2
    columns = 2
  } else if(graphSize === 3) {
    rows = 1
    columns = 3
  } else if(graphSize === 2) {
    rows = 1
    columns = 2
  }

  const layout = {
    name: `POFprojections${startKey}`,
    title: `POF Projections for CMLs ${jsonObject[startKey].cmlId}[${startKey}]-${jsonObject[endKey].cmlId}[${endKey}]`,
    grid: {
      rows,
      columns,
      pattern: 'independent',
    },
  }
  const graphs = {}
  const traces = []
  const annotations = []
  let j = 0
  for(let i = startKey; i <= endKey; i += 1) {
    if(j === 0) {
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].pof,
        showlegend: false,
        mode: 'lines',
        type: 'scatter',
        name: 'POF',
        line: {
          color: 'blue',
        },
      }
      traces.push(traceFifty)
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Analysis Date',
        line: {
          color: 'grey',
          dash: 'dash',
        },
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Next TA Date',
        line: {
          color: 'Brown',
          dash: 'dash',
        },
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Two TAs Date',
        line: {
          color: 'Black',
          dash: 'dash',
        },
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: 'x domain',
        yref: 'y domain',
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
      layout.yaxis = {
        type: 'log',
        autorange: true,
      }
    } else {
      const xaxis = `x${j + 1}`
      const yaxis = `y${j + 1}`
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].pof,
        mode: 'lines',
        type: 'scatter',
        name: `POF ${xaxis}`,
        line: {
          color: 'blue',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceFifty)
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Analysis Date ${xaxis}`,
        line: {
          color: 'grey',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Next TA Date ${xaxis}`,
        line: {
          color: 'Brown',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Two TAs Date ${xaxis}`,
        line: {
          color: 'Black',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: `${xaxis} domain`,
        yref: `${yaxis} domain`,
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
      layout[`yaxis${j + 1}`] = {
        type: 'log',
        autorange: true,
      }
    }
    j += 1
  }
  annotations.push({
    showarrow: false,
    text: 'Date',
    x: 0.5,
    xanchor: 'center',
    xref: 'paper',
    y: -0.1,
    yanchor: 'bottom',
    yref: 'paper',
  })
  annotations.push({
    showarrow: false,
    text: 'Probability of Failure',
    x: -0.05,
    xanchor: 'left',
    xref: 'paper',
    y: 0.5,
    yanchor: 'bottom',
    yref: 'paper',
    textangle: -90,
  })
  layout.annotations = annotations
  const graphId = `pofgraph${startKey}`
  graphs[graphId] = {
    traces,
    layout,
  }
  return graphs
}

export function getThicknessProjectionGraphs2(jsonData, startIndex, endIndex, dates) {
  // https://plotly.com/python/marker-style/
  const jsonObject = JSON.parse(jsonData[0])
  const startKey = parseInt(startIndex, 10)
  const endKey = parseInt(endIndex, 10)
  const graphSize = endKey - startKey + 1
  const analysisDate = dates[0]
  const nextTaDate = dates[1]
  const taFrequency = dates[2]
  const taDateString = nextTaDate.split('/')
  const taDate = `${taDateString[2]}-${taDateString[0]}-${taDateString[1]}`
  const twoTaDate = `${parseInt(taDateString[2], 10) + taFrequency}-${taDateString[0]}-${taDateString[1]}`
  let numReplacements = 0
  let utLenData = 0
  let utOutlierLenData = 0
  let rtLenData = 0
  let rtOutlierLenData = 0
  let otherLenData = 0
  let otherOutlierLenData = 0
  const replacementsColorSchemes = ['black', 'red', 'green', 'orange', 'blue', 'brown', 'grey']
  let rows = 1
  let columns = 1
  if(graphSize > 6) {
    rows = 3
    columns = 3
  } else if(graphSize > 4) {
    rows = 2
    columns = 3
  } else if(graphSize > 3) {
    rows = 2
    columns = 2
  } else if(graphSize > 2) {
    rows = 1
    columns = 3
  } else if(graphSize > 1) {
    rows = 1
    columns = 2
  }
  const graphs = {}
  const traces = []
  const annotations = []
  let j = 0
  for(let i = startKey; i <= endKey; i += 1) {
    if(j === 0) {
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound',
        line: {
          color: 'blue',
        },
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: 'Median',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Upper Bound',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 159)',
        line: {
          color: 'green',
        },
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              if(typeof jsonObject[i].data.utOutlier === 'undefined' || !jsonObject[i].data.utOutlier[k].includes(1)) {
                const traceDataUt = {
                  x: jsonObject[i].data.utDate[k],
                  y: jsonObject[i].data.utThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: 'UT Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                }
                traces.push(traceDataUt)
                utLenData += 1
              } else if(typeof jsonObject[i].data.utOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.utDate[k].length; m += 1) {
                  if(jsonObject[i].data.utOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.utDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.utThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.utDate[k][m])
                    thicksNorm.push(jsonObject[i].data.utThick[k][m])
                  }
                }
                const traceDataUt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'UT Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                }
                const traceDataUtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'UT Data Outliers',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle-open',
                  },
                }
                traces.push(traceDataUt)
                traces.push(traceDataUtOutlier)
                utOutlierLenData += 1
                utLenData += 1
              }
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              if(typeof jsonObject[i].data.rtOutlier === 'undefined' || !jsonObject[i].data.rtOutlier[k].includes(1)) {
                const traceDataRt = {
                  x: jsonObject[i].data.rtDate[k],
                  y: jsonObject[i].data.rtThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: 'RT Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                }
                traces.push(traceDataRt)
                rtLenData += 1
              } else if(typeof jsonObject[i].data.rtOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.rtDate[k].length; m += 1) {
                  if(jsonObject[i].data.rtOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.rtDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.rtThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.rtDate[k][m])
                    thicksNorm.push(jsonObject[i].data.rtThick[k][m])
                  }
                }
                const traceDataRt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'RT Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                }
                const traceDataRtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'RT Data Outliers',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square-open',
                  },
                }
                traces.push(traceDataRt)
                traces.push(traceDataRtOutlier)
                rtOutlierLenData += 1
                rtLenData += 1
              }
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              if(typeof jsonObject[i].data.otherOutlier === 'undefined' || !jsonObject[i].data.otherOutlier[k].includes(1)) {
                const traceDataOther = {
                  x: jsonObject[i].data.otherDate[k],
                  y: jsonObject[i].data.otherThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: 'Other Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                }
                traces.push(traceDataOther)
                otherLenData += 1
              } else if(typeof jsonObject[i].data.otherOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.otherDate[k].length; m += 1) {
                  if(jsonObject[i].data.otherOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.otherDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.otherThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.otherDate[k][m])
                    thicksNorm.push(jsonObject[i].data.otherThick[k][m])
                  }
                }
                const traceDataOther = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'Other Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                }
                const traceDataOtherOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: 'Other Data Outliers',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond-open',
                  },
                }
                traces.push(traceDataOther)
                traces.push(traceDataOtherOutlier)
                otherOutlierLenData += 1
                otherLenData += 1
              }
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              if(typeof jsonObject[i].data.utOutlier === 'undefined' || !jsonObject[i].data.utOutlier[k].includes(1)) {
                const traceDataUt = {
                  x: jsonObject[i].data.utDate[k],
                  y: jsonObject[i].data.utThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: `UT Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                }
                if(numReplacements > k) {
                  traceDataUt.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataUt)
                utLenData += 1
              } else if(typeof jsonObject[i].data.utOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.utDate[k].length; m += 1) {
                  if(jsonObject[i].data.utOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.utDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.utThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.utDate[k][m])
                    thicksNorm.push(jsonObject[i].data.utThick[k][m])
                  }
                }
                const traceDataUt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: `UT Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                }
                const traceDataUtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: `UT Data Outliers Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle-open',
                  },
                }
                if(numReplacements > k) {
                  traceDataUt.showlegend = false
                  traceDataUtOutlier.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataUt)
                traces.push(traceDataUtOutlier)
                utOutlierLenData += 1
                utLenData += 1
              }
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              if(typeof jsonObject[i].data.rtOutlier === 'undefined' || !jsonObject[i].data.rtOutlier[k].includes(1)) {
                const traceDataRt = {
                  x: jsonObject[i].data.rtDate[k],
                  y: jsonObject[i].data.rtThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: `RT Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                }
                if(numReplacements > k) {
                  traceDataRt.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataRt)
                rtLenData += 1
              } else if(typeof jsonObject[i].data.rtOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.rtDate[k].length; m += 1) {
                  if(jsonObject[i].data.rtOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.rtDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.rtThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.rtDate[k][m])
                    thicksNorm.push(jsonObject[i].data.rtThick[k][m])
                  }
                }
                const traceDataRt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: `RT Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                }
                const traceDataRtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: `RT Data Outliers Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square-open',
                  },
                }
                if(numReplacements > k) {
                  traceDataRt.showlegend = false
                  traceDataRtOutlier.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataRt)
                traces.push(traceDataRtOutlier)
                rtOutlierLenData += 1
                rtLenData += 1
              }
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              if(typeof jsonObject[i].data.otherOutlier === 'undefined' || !jsonObject[i].data.otherOutlier[k].includes(1)) {
                const traceDataOther = {
                  x: jsonObject[i].data.otherDate[k],
                  y: jsonObject[i].data.otherThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: `Other Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                }
                if(numReplacements > k) {
                  traceDataOther.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataOther)
                otherLenData += 1
              } else if(typeof jsonObject[i].data.otherOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.otherDate[k].length; m += 1) {
                  if(jsonObject[i].data.otherOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.otherDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.otherThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.otherDate[k][m])
                    thicksNorm.push(jsonObject[i].data.otherThick[k][m])
                  }
                }
                const traceDataOther = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  name: `Other Data Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                }
                const traceDataOtherOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  name: `Other Data Outliers Replacement ${k}`,
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond-open',
                  },
                }
                if(numReplacements > k) {
                  traceDataOther.showlegend = false
                  traceDataOtherOutlier.showlegend = false
                } else {
                  numReplacements += 1
                }
                traces.push(traceDataOther)
                traces.push(traceDataOtherOutlier)
                otherOutlierLenData += 1
                otherLenData += 1
              }
            }
          }
        }
      }
      const traceTmin = {
        x: jsonObject[i].date,
        y: jsonObject[i].tmin,
        mode: 'lines',
        type: 'scatter',
        name: 'Tmin',
        line: {
          color: 'red',
          dash: 'dash',
        },
      }
      traces.push(traceTmin)
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Analysis Date',
        line: {
          color: 'grey',
          dash: 'dash',
        },
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Next TA Date',
        line: {
          color: 'Brown',
          dash: 'dash',
        },
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Two TAs Date',
        line: {
          color: 'Black',
          dash: 'dash',
        },
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: 'x domain',
        yref: 'y domain',
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    } else {
      const xaxis = `x${(j + 1).toString()}`
      const yaxis = `y${(j + 1).toString()}`
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: `Lower Bound ${xaxis}`,
        line: {
          color: 'blue',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: `Median ${xaxis}`,
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: `Upper Bound ${xaxis}`,
        fillcolor: 'rgb(149, 247, 159)',
        fill: 'tonexty',
        line: {
          color: 'green',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        // k is for num replacements
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              if(typeof jsonObject[i].data.utOutlier === 'undefined' || !jsonObject[i].data.utOutlier[k].includes(1)) {
                const traceDataUt = {
                  x: jsonObject[i].data.utDate[k],
                  y: jsonObject[i].data.utThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                  xaxis,
                  yaxis,
                }
                if(utLenData > k) {
                  traceDataUt.showlegend = false
                  traceDataUt.name = `UT Data ${xaxis}`
                } else {
                  utLenData += 1
                  traceDataUt.name = 'UT Data'
                }
                traces.push(traceDataUt)
              } else if(typeof jsonObject[i].data.utOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.utDate[k].length; m += 1) {
                  if(jsonObject[i].data.utOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.utDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.utThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.utDate[k][m])
                    thicksNorm.push(jsonObject[i].data.utThick[k][m])
                  }
                }
                const traceDataUt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                  xaxis,
                  yaxis,
                }
                if(utLenData > k) {
                  traceDataUt.showlegend = false
                  traceDataUt.name = `UT Data ${xaxis}`
                } else {
                  utLenData += 1
                  traceDataUt.name = 'UT Data'
                }
                const traceDataUtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(utOutlierLenData > k) {
                  traceDataUtOutlier.showlegend = false
                  traceDataUtOutlier.name = `UT Data Outliers ${xaxis}`
                } else {
                  utOutlierLenData += 1
                  traceDataUtOutlier.name = 'UT Data Outliers'
                }
                traces.push(traceDataUt)
                traces.push(traceDataUtOutlier)
              }
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              if(typeof jsonObject[i].data.rtOutlier === 'undefined' || !jsonObject[i].data.rtOutlier[k].includes(1)) {
                const traceDataRt = {
                  x: jsonObject[i].data.rtDate[k],
                  y: jsonObject[i].data.rtThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  name: 'RT Data',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtLenData > k) {
                  traceDataRt.showlegend = false
                  traceDataRt.name = `RT Data ${xaxis}`
                } else {
                  rtLenData += 1
                  traceDataRt.name = 'RT Data'
                }
                traces.push(traceDataRt)
              } else if(typeof jsonObject[i].data.rtOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.rtDate[k].length; m += 1) {
                  if(jsonObject[i].data.rtOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.rtDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.rtThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.rtDate[k][m])
                    thicksNorm.push(jsonObject[i].data.rtThick[k][m])
                  }
                }
                const traceDataRt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtLenData > k) {
                  traceDataRt.showlegend = false
                  traceDataRt.name = `RT Data ${xaxis}`
                } else {
                  rtLenData += 1
                  traceDataRt.name = 'RT Data'
                }
                const traceDataRtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtOutlierLenData > k) {
                  traceDataRtOutlier.showlegend = false
                  traceDataRtOutlier.name = `RT Data Outliers ${xaxis}`
                } else {
                  rtOutlierLenData += 1
                  traceDataRtOutlier.name = 'RT Data Outliers'
                }
                traces.push(traceDataRt)
                traces.push(traceDataRtOutlier)
              }
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              if(typeof jsonObject[i].data.otherOutlier === 'undefined' || !jsonObject[i].data.otherOutlier[k].includes(1)) {
                const traceDataOther = {
                  x: jsonObject[i].data.otherDate[k],
                  y: jsonObject[i].data.otherThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherLenData > k) {
                  traceDataOther.showlegend = false
                  traceDataOther.name = `Other Data ${xaxis}`
                } else {
                  otherLenData += 1
                  traceDataOther.name = 'Other Data'
                }
                traces.push(traceDataOther)
              } else if(typeof jsonObject[i].data.otherOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.otherDate[k].length; m += 1) {
                  if(jsonObject[i].data.otherOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.otherDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.otherThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.otherDate[k][m])
                    thicksNorm.push(jsonObject[i].data.otherThick[k][m])
                  }
                }
                const traceDataOther = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherLenData > k) {
                  traceDataOther.showlegend = false
                  traceDataOther.name = `Other Data ${xaxis}`
                } else {
                  otherLenData += 1
                  traceDataOther.name = 'Other Data'
                }
                const traceDataOtherOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherOutlierLenData > k) {
                  traceDataOtherOutlier.showlegend = false
                  traceDataOtherOutlier.name = `Other Data Outliers ${xaxis}`
                } else {
                  otherOutlierLenData += 1
                  traceDataOtherOutlier.name = 'Other Data Outliers'
                }
                traces.push(traceDataOther)
                traces.push(traceDataOtherOutlier)
              }
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              if(typeof jsonObject[i].data.utOutlier === 'undefined' || !jsonObject[i].data.utOutlier[k].includes(1)) {
                const traceDataUt = {
                  x: jsonObject[i].data.utDate[k],
                  y: jsonObject[i].data.utThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                  xaxis,
                  yaxis,
                }
                if(utLenData > k) {
                  traceDataUt.showlegend = false
                  traceDataUt.name = `UT Data ${xaxis} Replacement ${k}`
                } else {
                  utLenData += 1
                  traceDataUt.name = `UT Data Replacement ${k}`
                }
                traces.push(traceDataUt)
              } else if(typeof jsonObject[i].data.utOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.utDate[k].length; m += 1) {
                  if(jsonObject[i].data.utOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.utDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.utThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.utDate[k][m])
                    thicksNorm.push(jsonObject[i].data.utThick[k][m])
                  }
                }
                const traceDataUt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle',
                  },
                  xaxis,
                  yaxis,
                }
                if(utLenData > k) {
                  traceDataUt.showlegend = false
                  traceDataUt.name = `UT Data ${xaxis} Replacement ${k}`
                } else {
                  utLenData += 1
                  traceDataUt.name = `UT Data Replacement ${k}`
                }
                const traceDataUtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'circle-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(utOutlierLenData > k) {
                  traceDataUtOutlier.showlegend = false
                  traceDataUtOutlier.name = `UT Data Outliers ${xaxis} Replacement ${k}`
                } else {
                  utOutlierLenData += 1
                  traceDataUtOutlier.name = `UT Data Outliers Replacement ${k}`
                }
                traces.push(traceDataUt)
                traces.push(traceDataUtOutlier)
              }
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              if(typeof jsonObject[i].data.rtOutlier === 'undefined' || !jsonObject[i].data.rtOutlier[k].includes(1)) {
                const traceDataRt = {
                  x: jsonObject[i].data.rtDate[k],
                  y: jsonObject[i].data.rtThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtLenData > k) {
                  traceDataRt.showlegend = false
                  traceDataRt.name = `RT Data ${xaxis} Replacement ${k}`
                } else {
                  rtLenData += 1
                  traceDataRt.name = `RT Data Replacement ${k}`
                }
                traces.push(traceDataRt)
              } else if(typeof jsonObject[i].data.rtOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.rtDate[k].length; m += 1) {
                  if(jsonObject[i].data.rtOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.rtDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.rtThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.rtDate[k][m])
                    thicksNorm.push(jsonObject[i].data.rtThick[k][m])
                  }
                }
                const traceDataRt = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtLenData > k) {
                  traceDataRt.showlegend = false
                  traceDataRt.name = `RT Data ${xaxis} Replacement ${k}`
                } else {
                  rtLenData += 1
                  traceDataRt.name = `RT Data Replacement ${k}`
                }
                const traceDataRtOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'square-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(rtOutlierLenData > k) {
                  traceDataRtOutlier.showlegend = false
                  traceDataRtOutlier.name = `RT Data Outliers ${xaxis} Replacement ${k}`
                } else {
                  rtOutlierLenData += 1
                  traceDataRtOutlier.name = `RT Data Outliers Replacement ${k}`
                }
                traces.push(traceDataRt)
                traces.push(traceDataRtOutlier)
              }
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              if(typeof jsonObject[i].data.otherOutlier === 'undefined' || !jsonObject[i].data.otherOutlier[k].includes(1)) {
                const traceDataOther = {
                  x: jsonObject[i].data.otherDate[k],
                  y: jsonObject[i].data.otherThick[k],
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherLenData > k) {
                  traceDataOther.showlegend = false
                  traceDataOther.name = `Other Data ${xaxis} Replacement ${k}`
                } else {
                  otherLenData += 1
                  traceDataOther.name = `Other Data Replacement ${k}`
                }
                traces.push(traceDataOther)
              } else if(typeof jsonObject[i].data.otherOutlier !== 'undefined') {
                const datesNorm = []
                const thicksNorm = []
                const datesOutlier = []
                const thicksOutlier = []
                for(let m = 0; m < jsonObject[i].data.otherDate[k].length; m += 1) {
                  if(jsonObject[i].data.otherOutlier[k][m] === 1) {
                    datesOutlier.push(jsonObject[i].data.otherDate[k][m])
                    thicksOutlier.push(jsonObject[i].data.otherThick[k][m])
                  } else {
                    datesNorm.push(jsonObject[i].data.otherDate[k][m])
                    thicksNorm.push(jsonObject[i].data.otherThick[k][m])
                  }
                }
                const traceDataOther = {
                  x: datesNorm,
                  y: thicksNorm,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherLenData > k) {
                  traceDataOther.showlegend = false
                  traceDataOther.name = `Other Data ${xaxis} Replacement ${k}`
                } else {
                  otherLenData += 1
                  traceDataOther.name = `Other Data Replacement ${k}`
                }
                const traceDataOtherOutlier = {
                  x: datesOutlier,
                  y: thicksOutlier,
                  mode: 'markers',
                  type: 'scatter',
                  marker: {
                    color: replacementsColorSchemes[k],
                    symbol: 'diamond-open',
                  },
                  xaxis,
                  yaxis,
                }
                if(otherOutlierLenData > k) {
                  traceDataOtherOutlier.showlegend = false
                  traceDataOtherOutlier.name = `Other Data Outliers ${xaxis} Replacement ${k}`
                } else {
                  otherOutlierLenData += 1
                  traceDataOtherOutlier.name = `Other Data Outliers Replacement ${k}`
                }
                traces.push(traceDataOther)
                traces.push(traceDataOtherOutlier)
              }
            }
          }
        }
      }
      const traceTmin = {
        x: jsonObject[i].date,
        y: jsonObject[i].tmin,
        mode: 'lines',
        type: 'scatter',
        name: `Tmin ${xaxis}`,
        line: {
          color: 'red',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceTmin)
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Analysis Date ${xaxis}`,
        line: {
          color: 'grey',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Next TA Date ${xaxis}`,
        line: {
          color: 'Brown',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [0, Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Two TAs Date ${xaxis}`,
        line: {
          color: 'Black',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: `${xaxis} domain`,
        yref: `${yaxis} domain`,
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    }
    j += 1
  }
  annotations.push({
    showarrow: false,
    text: 'Date',
    x: 0.5,
    xanchor: 'center',
    xref: 'paper',
    y: -0.1,
    yanchor: 'bottom',
    yref: 'paper',
  })
  annotations.push({
    showarrow: false,
    text: 'Thickness (in)',
    x: -0.05,
    xanchor: 'left',
    xref: 'paper',
    y: 0.5,
    yanchor: 'bottom',
    yref: 'paper',
    textangle: -90,
  })
  const graphId = `Thicknessgraph${startKey}`
  graphs[graphId] = {
    traces,
    layout: {
      name: `thicknessProjection${startKey}`,
      title: `Thickness Projections for CMLs ${jsonObject[startKey].cmlId}[${startKey}]-${jsonObject[endKey].cmlId}[${endKey}]`,
      grid: {
        rows,
        columns,
        pattern: 'independent',
      },
      annotations,
    },
  }
  return graphs
}

// export function getMetalLossProjectionGraphs(jsonData, key) {
//   const jsonObject = JSON.parse(jsonData[0]);
//   const graphs = {};
//   const traces = [];
//   const traceData = {
//     x: jsonObject[key][0].dataTime,
//     y: jsonObject[key][0].dataLoss,
//     mode: 'markers',
//     type: 'scatter',
//     name: 'Data',
//   };
//   traces.push(traceData);
//   const traceFifty = {
//     x: jsonObject[key][0].time,
//     y: jsonObject[key][0].median,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Median',
//   };
//   traces.push(traceFifty);
//   const traceUpper = {
//     x: jsonObject[key][0].time,
//     y: jsonObject[key][0].upperBound,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Upper Bound',
//   };
//   traces.push(traceUpper);
//   const traceLower = {
//     x: jsonObject[key][0].time,
//     y: jsonObject[key][0].lowerBound,
//     mode: 'lines',
//     type: 'scatter',
//     name: 'Lower Bound',
//   };
//   traces.push(traceLower);
//   const graphId = `metalLossGraph${key}`;
//   graphs[graphId] = {
//     traces,
//     layout: {
//       name: `metalLossProjection${key}`,
//       title: `Metal Loss Projection for CML${key}`,
//       xaxis: {
//         title: 'Time (yr)',
//       },
//       yaxis: {
//         title: 'Metal Loss (mil)',
//         rangemode: 'tozero',
//       },
//     },
//   };
//   return graphs;
// }

export function getMetalLossProjectionGraphs2(jsonData, startIndex, endIndex, dates, problemType) {
  const jsonObject = JSON.parse(jsonData[0])
  const startKey = parseInt(startIndex, 10)
  const endKey = parseInt(endIndex, 10)
  const graphSize = endKey - startKey + 1
  const analysisDate = dates[0]
  const nextTaDate = dates[1]
  const taFrequency = dates[2]
  const taDateString = nextTaDate.split('/')
  const taDate = `${taDateString[2]}-${taDateString[0]}-${taDateString[1]}`
  const twoTaDate = `${parseInt(taDateString[2], 10) + taFrequency}-${taDateString[0]}-${taDateString[1]}`
  let numReplacements = 0
  let utLenData = 0
  let rtLenData = 0
  let otherLenData = 0
  const replacementsColorSchemes = ['black', 'red', 'green', 'orange', 'blue', 'brown', 'grey']
  let rows = 1
  let columns = 1
  if(graphSize > 6) {
    rows = 3
    columns = 3
  } else if(graphSize > 4) {
    rows = 2
    columns = 3
  } else if(graphSize > 3) {
    rows = 2
    columns = 2
  } else if(graphSize > 2) {
    rows = 1
    columns = 3
  } else if(graphSize > 1) {
    rows = 1
    columns = 2
  }
  const graphs = {}
  const traces = []
  const annotations = []
  let j = 0
  for(let i = startKey; i <= endKey; i += 1) {
    if(j === 0) {
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound',
        line: {
          color: 'blue',
        },
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: 'Median',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Upper Bound',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 159)',
        line: {
          color: 'green',
        },
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'UT Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
              }
              traces.push(traceDataUt)
              utLenData += 1
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'RT Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
              }
              traces.push(traceDataRt)
              rtLenData += 1
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'Other Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
              }
              traces.push(traceDataOther)
              otherLenData += 1
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `UT Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataUt.showlegend = false
              } else {
                numReplacements += 1
              }
              utLenData += 1
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `RT Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataRt.showlegend = false
              } else {
                numReplacements += 1
              }
              rtLenData += 1
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `Other Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataOther.showlegend = false
              } else {
                numReplacements += 1
              }
              otherLenData += 1
              traces.push(traceDataOther)
            }
          }
        }
      }
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Analysis Date',
        line: {
          color: 'grey',
          dash: 'dash',
        },
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Next TA Date',
        line: {
          color: 'Brown',
          dash: 'dash',
        },
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Two TAs Date',
        line: {
          color: 'Black',
          dash: 'dash',
        },
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: 'x domain',
        yref: 'y domain',
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    } else {
      const xaxis = `x${(j + 1).toString()}`
      const yaxis = `y${(j + 1).toString()}`
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: `Lower Bound ${xaxis}`,
        line: {
          color: 'blue',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: `Median ${xaxis}`,
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: `Upper Bound ${xaxis}`,
        fillcolor: 'rgb(149, 247, 159)',
        fill: 'tonexty',
        line: {
          color: 'green',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(utLenData > k) {
                traceDataUt.showlegend = false
                traceDataUt.name = `UT Data ${xaxis}`
              } else {
                utLenData += 1
                traceDataUt.name = 'UT Data'
              }
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(rtLenData > k) {
                traceDataRt.showlegend = false
                traceDataRt.name = `RT Data ${xaxis}`
              } else {
                rtLenData += 1
                traceDataRt.name = 'RT Data'
              }
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `Other Data ${xaxis}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(otherLenData > k) {
                traceDataOther.showlegend = false
                traceDataOther.name = `Other Data ${xaxis}`
              } else {
                otherLenData += 1
                traceDataOther.name = 'Other Data'
              }
              traces.push(traceDataOther)
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(utLenData > k) {
                traceDataUt.showlegend = false
                traceDataUt.name = `UT Data ${xaxis} Replacement ${k}`
              } else {
                utLenData += 1
                traceDataUt.name = `UT Data Replacement ${k}`
              }
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(rtLenData > k) {
                traceDataRt.showlegend = false
                traceDataRt.name = `RT Data ${xaxis} Replacement ${k}`
              } else {
                rtLenData += 1
                traceDataRt.name = `RT Data Replacement ${k}`
              }
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(otherLenData > k) {
                traceDataOther.showlegend = false
                traceDataOther.name = `Other Data ${xaxis} Replacement ${k}`
              } else {
                otherLenData += 1
                traceDataOther.name = `Other Data Replacement ${k}`
              }
              traces.push(traceDataOther)
            }
          }
        }
      }
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Analysis Date ${xaxis}`,
        line: {
          color: 'grey',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Next TA Date ${xaxis}`,
        line: {
          color: 'Brown',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Two TAs Date ${xaxis}`,
        line: {
          color: 'Black',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: `${xaxis} domain`,
        yref: `${yaxis} domain`,
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    }
    j += 1
  }
  annotations.push({
    showarrow: false,
    text: 'Date',
    x: 0.5,
    xanchor: 'center',
    xref: 'paper',
    y: -0.1,
    yanchor: 'bottom',
    yref: 'paper',
  })
  if(problemType === 'CS_OLD') {
    annotations.push({
      showarrow: false,
      text: 'Metal Loss (in)',
      x: -0.05,
      xanchor: 'left',
      xref: 'paper',
      y: 0.5,
      yanchor: 'bottom',
      yref: 'paper',
      textangle: -90,
    })
  } else {
    annotations.push({
      showarrow: false,
      text: 'Metal Loss (mils)',
      x: -0.05,
      xanchor: 'left',
      xref: 'paper',
      y: 0.5,
      yanchor: 'bottom',
      yref: 'paper',
      textangle: -90,
    })
  }
  const graphId = `MetalLossGraph${startKey}`
  graphs[graphId] = {
    traces,
    layout: {
      name: `metalLossProjection${startKey}`,
      title: `Metal Loss Projections for CMLs ${jsonObject[startKey].cmlId}[${startKey}]-${jsonObject[endKey].cmlId}[${endKey}]`,
      grid: {
        rows,
        columns,
        pattern: 'independent',
      },
      annotations,
    },
  }
  return graphs
}

export function getMetalLossProjectionGraphsR(jsonData, startIndex, endIndex, dates, problemType) {
  const jsonObject = JSON.parse(jsonData[0])
  const startKey = parseInt(startIndex, 10)
  const endKey = parseInt(endIndex, 10)
  const graphSize = endKey - startKey + 1
  const analysisDate = dates[0]
  const nextTaDate = dates[1]
  const taFrequency = dates[2]
  const taDateString = nextTaDate.split('/')
  const taDate = `${taDateString[2]}-${taDateString[0]}-${taDateString[1]}`
  const twoTaDate = `${parseInt(taDateString[2], 10) + taFrequency}-${taDateString[0]}-${taDateString[1]}`
  let numReplacements = 0
  let utLenData = 0
  let rtLenData = 0
  let otherLenData = 0
  const replacementsColorSchemes = ['black', 'red', 'green', 'orange', 'blue', 'brown', 'grey']

  const gridDimensions = getGraphDimensions(graphSize)

  const rows = gridDimensions.rows
  const columns = gridDimensions.columns

  const graphs = {}
  const traces = []
  const annotations = []
  let j = 0
  for(let i = startKey; i <= endKey; i += 1) {
    if(j === 0) {
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound',
        line: {
          color: 'blue',
        },
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: 'Median',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: 'Upper Bound',
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 159)',
        line: {
          color: 'green',
        },
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'UT Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
              }
              traces.push(traceDataUt)
              utLenData += 1
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'RT Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
              }
              traces.push(traceDataRt)
              rtLenData += 1
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: 'Other Data',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
              }
              traces.push(traceDataOther)
              otherLenData += 1
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `UT Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataUt.showlegend = false
              } else {
                numReplacements += 1
              }
              utLenData += 1
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `RT Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataRt.showlegend = false
              } else {
                numReplacements += 1
              }
              rtLenData += 1
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `Other Data Replacement ${k}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
              }
              if(numReplacements > k) {
                traceDataOther.showlegend = false
              } else {
                numReplacements += 1
              }
              otherLenData += 1
              traces.push(traceDataOther)
            }
          }
        }
      }
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Analysis Date',
        line: {
          color: 'grey',
          dash: 'dash',
        },
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Next TA Date',
        line: {
          color: 'Brown',
          dash: 'dash',
        },
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: 'Two TAs Date',
        line: {
          color: 'Black',
          dash: 'dash',
        },
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: 'x domain',
        yref: 'y domain',
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    } else {
      const xaxis = `x${(j + 1).toString()}`
      const yaxis = `y${(j + 1).toString()}`
      const traceLower = {
        x: jsonObject[i].date,
        y: jsonObject[i].lowerBound,
        mode: 'lines',
        type: 'scatter',
        name: `Lower Bound ${xaxis}`,
        line: {
          color: 'blue',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceLower)
      const traceFifty = {
        x: jsonObject[i].date,
        y: jsonObject[i].median,
        mode: 'lines',
        type: 'scatter',
        name: `Median ${xaxis}`,
        fill: 'tonexty',
        fillcolor: 'rgb(149, 247, 255)',
        line: {
          color: 'black',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceFifty)
      const traceUpper = {
        x: jsonObject[i].date,
        y: jsonObject[i].upperBound,
        mode: 'lines',
        type: 'scatter',
        name: `Upper Bound ${xaxis}`,
        fillcolor: 'rgb(149, 247, 159)',
        fill: 'tonexty',
        line: {
          color: 'green',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceUpper)
      if(typeof jsonObject[i].data.utDate !== 'undefined') {
        for(let k = 0; k < jsonObject[i].data.utDate.length; k += 1) {
          if(k === 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(utLenData > k) {
                traceDataUt.showlegend = false
                traceDataUt.name = `UT Data ${xaxis}`
              } else {
                utLenData += 1
                traceDataUt.name = 'UT Data'
              }
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(rtLenData > k) {
                traceDataRt.showlegend = false
                traceDataRt.name = `RT Data ${xaxis}`
              } else {
                rtLenData += 1
                traceDataRt.name = 'RT Data'
              }
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                name: `Other Data ${xaxis}`,
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(otherLenData > k) {
                traceDataOther.showlegend = false
                traceDataOther.name = `Other Data ${xaxis}`
              } else {
                otherLenData += 1
                traceDataOther.name = 'Other Data'
              }
              traces.push(traceDataOther)
            }
          } else if(k > 0) {
            if(jsonObject[i].data.utDate[k].length > 0) {
              const traceDataUt = {
                x: jsonObject[i].data.utDate[k],
                y: jsonObject[i].data.utLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(utLenData > k) {
                traceDataUt.showlegend = false
                traceDataUt.name = `UT Data ${xaxis} Replacement ${k}`
              } else {
                utLenData += 1
                traceDataUt.name = `UT Data Replacement ${k}`
              }
              traces.push(traceDataUt)
            }
            if(jsonObject[i].data.rtDate[k].length > 0) {
              const traceDataRt = {
                x: jsonObject[i].data.rtDate[k],
                y: jsonObject[i].data.rtLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'x',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(rtLenData > k) {
                traceDataRt.showlegend = false
                traceDataRt.name = `RT Data ${xaxis} Replacement ${k}`
              } else {
                rtLenData += 1
                traceDataRt.name = `RT Data Replacement ${k}`
              }
              traces.push(traceDataRt)
            }
            if(jsonObject[i].data.otherDate[k].length > 0) {
              const traceDataOther = {
                x: jsonObject[i].data.otherDate[k],
                y: jsonObject[i].data.otherLoss[k],
                mode: 'markers',
                type: 'scatter',
                marker: {
                  color: replacementsColorSchemes[k],
                  symbol: 'diamond',
                  size: 10,
                },
                xaxis,
                yaxis,
              }
              if(otherLenData > k) {
                traceDataOther.showlegend = false
                traceDataOther.name = `Other Data ${xaxis} Replacement ${k}`
              } else {
                otherLenData += 1
                traceDataOther.name = `Other Data Replacement ${k}`
              }
              traces.push(traceDataOther)
            }
          }
        }
      }
      const traceAnalysisDate = {
        x: [analysisDate, analysisDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Analysis Date ${xaxis}`,
        line: {
          color: 'grey',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceAnalysisDate)
      const traceNextTaDate = {
        x: [taDate, taDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Next TA Date ${xaxis}`,
        line: {
          color: 'Brown',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(traceNextTaDate)
      const tracetwoTaDate = {
        x: [twoTaDate, twoTaDate],
        y: [Math.min(...jsonObject[i].lowerBound), Math.max(...jsonObject[i].upperBound)],
        mode: 'lines',
        type: 'line',
        name: `Two TAs Date ${xaxis}`,
        line: {
          color: 'Black',
          dash: 'dash',
        },
        showlegend: false,
        xaxis,
        yaxis,
      }
      traces.push(tracetwoTaDate)
      annotations.push({
        x: 0.5,
        y: 1.05,
        xref: `${xaxis} domain`,
        yref: `${yaxis} domain`,
        text: `CML ${jsonObject[i].cmlId}`,
        showarrow: false,
      })
    }
    j += 1
  }
  annotations.push({
    showarrow: false,
    text: 'Date',
    x: 0.5,
    xanchor: 'center',
    xref: 'paper',
    y: -0.1,
    yanchor: 'bottom',
    yref: 'paper',
  })
  if(problemType === 'CS_OLD') {
    annotations.push({
      showarrow: false,
      text: 'Metal Loss (in)',
      x: -0.05,
      xanchor: 'left',
      xref: 'paper',
      y: 0.5,
      yanchor: 'bottom',
      yref: 'paper',
      textangle: -90,
    })
  } else {
    annotations.push({
      showarrow: false,
      text: 'Metal Loss (mils)',
      x: -0.05,
      xanchor: 'left',
      xref: 'paper',
      y: 0.5,
      yanchor: 'bottom',
      yref: 'paper',
      textangle: -90,
    })
  }
  const graphId = `MetalLossGraph${startKey}`
  graphs[graphId] = {
    traces,
    layout: {
      name: `metalLossProjection${startKey}`,
      title: `Metal Loss Projections for CMLs ${jsonObject[startKey].cmlId}[${startKey}]-${jsonObject[endKey].cmlId}[${endKey}]`,
      grid: {
        rows,
        columns,
        pattern: 'independent',
      },
      annotations,
    },
  }
  return graphs
}

function getGraphDimensions(graphSize) {
  let rows = 1
  let columns = 1

  if(graphSize > 6) {
    rows = 3
    columns = 3
  } else if(graphSize > 4) {
    rows = 2
    columns = 3
  } else if(graphSize > 3) {
    rows = 2
    columns = 2
  } else if(graphSize > 2) {
    rows = 1
    columns = 3
  } else if(graphSize > 1) {
    rows = 1
    columns = 2
  }

  const dimensions = {
    rows: rows,
    columns: columns
  }
  return dimensions

}

export function getGelmanRubinGraphs(jsonData, key) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const traces = []
  const trace1 = {
    x: jsonObject[key].xData,
    y: jsonObject[key].yDataMedian,
    mode: 'lines',
    type: 'scatter',
    name: 'Median',
  }
  const trace2 = {
    x: jsonObject[key].xData,
    y: jsonObject[key].yDataUpperBound,
    mode: 'lines',
    type: 'scatter',
    name: '97.5%',
  }
  traces.push(trace1)
  traces.push(trace2)
  const graphId = `GelmanRubin${key}`
  graphs[graphId] = {
    traces,
    layout: {
      name: `${key}`,
      title: `${key} Gelman Rubin Plot`,
      barmode: 'overlay',
      xaxis: {
        title: 'Sample Number',
      },
      yaxis: {
        title: `${key} Shrinkage Factor`,
      },
    },
  }
  return graphs
}

export function getAcfErrorBoxPlots(results) {
  const jsonDataAll = JSON.parse(results.acfErrPages[0])
  const acfLabelsAll = JSON.parse(results.acfErrLabels[0])
  const jsonKeysData = Object.keys(jsonDataAll)
  const jsonKeysLabels = Object.keys(acfLabelsAll)
  let jsonData = []
  let acfLabels = []
  jsonKeysData.forEach((key) => {
    if(key === 'pages') {
      jsonData = jsonDataAll[key]
    }
  })
  jsonKeysLabels.forEach((key) => {
    if(key === 'labels') {
      acfLabels = acfLabelsAll[key]
    }
  })
  const graphs = {}
  for(let j = 0; j < acfLabels.length; j += 1) {
    const traces = []
    for(let i = 0; i < acfLabels[j].length; i += 1) {
      const trace = {
        y: jsonData[j][i],
        opacity: 0.5,
        type: 'violin',
        name: `ACF ${acfLabels[j][i]}`,
      }
      traces.push(trace)
    }
    graphs[`${j.toString()}`] = {
      traces,
      layout: {
        name: 'acfError',
        title: 'ACF Error Violin Plots Plot',
        showlegend: false,
        xaxis: {
          title: 'ACF ID',
          type: 'category',
        },
        yaxis: {
          title: 'Error',
          type: 'linear',
        },
      },
    }
  }
  return graphs
}

export function getCmlErrorBoxPlots(results) {
  const jsonDataAll = JSON.parse(results.cmlErrPages[0])
  const cmlLabelsAll = JSON.parse(results.cmlErrLabels[0])
  const jsonKeysData = Object.keys(jsonDataAll)
  const jsonKeysLabels = Object.keys(cmlLabelsAll)
  let jsonData = []
  let cmlLabels = []
  jsonKeysData.forEach((key) => {
    if(key === 'pages') {
      jsonData = jsonDataAll[key]
    }
  })
  jsonKeysLabels.forEach((key) => {
    if(key === 'labels') {
      cmlLabels = cmlLabelsAll[key]
    }
  })
  const graphs = {}
  for(let j = 0; j < cmlLabels.length; j += 1) {
    const traces = []
    for(let i = 0; i < cmlLabels[j].length; i += 1) {
      const trace = {
        y: jsonData[j][i],
        opacity: 0.5,
        type: 'violin',
        name: `CML ${cmlLabels[j][i]}`,
      }
      traces.push(trace)
    }
    graphs[`${j.toString()}`] = {
      traces,
      layout: {
        name: 'cmlError',
        title: 'CML Error Violin Plots Plot',
        showlegend: false,
        xaxis: {
          title: 'CML ID',
          type: 'category',
        },
        yaxis: {
          title: 'Error',
          type: 'linear',
        },
      },
    }
  }
  return graphs
}

export function getCircCrGraph1(jsonData) {
  const jsonObject = JSON.parse(jsonData[0])
  const circCorrRates = jsonObject.circuit[0].circuit_corrosion_rates

  const traces = []
  const circFinalUpperFence = Math.max(circCorrRates.max, circCorrRates.B50 + 1.5 * (circCorrRates.B75 - circCorrRates.B25))
  const circFinalLowerFence = Math.min(circCorrRates.min, circCorrRates.B50 - 1.5 * (circCorrRates.B75 - circCorrRates.B25))

  const trace = {
    type: 'box',
    y: [circCorrRates.min, circCorrRates.B25, circCorrRates.B25, circCorrRates.B50, circCorrRates.B75, circCorrRates.B75, circCorrRates.max],
  }

  traces.push(trace)

  const graph = {
    traces,
    layout: {
      name: 'circCRPLot',
      title: 'Circuit Corrosion Rate Box Whisker Plot',
      showlegend: false,
      xaxis: {
        title: 'Circuit',
      },
      yaxis: {},
    },
  }
  return (graph)
}

export function getCircCrGraph(circuit, stringArrayData) {
  const circCorrRates = stringArrayData.map(Number)
  const traces = []

  const trace = {
    name: circuit,
    type: 'box',
    y: circCorrRates,
    boxmean: true,
  }

  traces.push(trace)

  const graph = {
    traces,
    layout: {
      name: 'circCRPLot',
      title: 'Circuit Corrosion Rate Box Whisker Plot(from Data)',
      showlegend: false,
      hovermode: false,
      xaxis: {
        title: 'Circuit',
      },
      yaxis: {},
    },
  }
  return (graph)
}

export function getAcfPageItems(acfCrPages, acfCrLabels) {
  const jsonObject = JSON.parse(acfCrPages[0])
  const labels = JSON.parse(acfCrLabels)
  const pageCount = jsonObject.pages.length
  const items = []

  for(let page = 0; page < pageCount; page++) {
    const acfGroupCount = jsonObject.pages[page].length
    let acfLabelsPage = ''
    let acfLabels = ''
    for(let acfGroup = 0; acfGroup < acfGroupCount; acfGroup++) {
      acfLabels += labels.labels[page][acfGroup] + ', '
    }
    acfLabelsPage = `<ACF(s) ${acfLabels} - page ${page + 1} of ${pageCount + 1}`
  }

  return (items)
}

export function getAcfCrGraph(acfCrPages, acfCrLabels, acfCrColors) {
  const jsonObject = JSON.parse(acfCrPages[0])
  const pageCount = jsonObject.pages.length
  const graphs = []
  const labels = JSON.parse(acfCrLabels)
  const colors = JSON.parse(acfCrColors)

  for(let page = 0; page < pageCount; page++) {
    const traces = []
    const acfGroupCount = jsonObject.pages[page].length
    for(let acfGroup = 0; acfGroup < acfGroupCount; acfGroup++) {
      const acfLabels = labels.labels[page][acfGroup]
      const acfColors = colors.colors[page][acfGroup]
      const trace = {
        y: jsonObject.pages[page][acfGroup],
        type: 'box',
        name: `ACF Gr ${acfLabels}`,
        marker: {
          color: acfColors,
        },
      }
      traces.push(trace)
    }

    graphs[`${page.toString()}`] = {
      traces,
      layout: {
        name: `ACFCrPage${page}`,
        title: 'ACF Corrosion Rates',
        showlegend: false,
        hovermode: false,
        xaxis: {
          title: 'ACF Group(s)',
        },
        yaxis: {
          title: 'Corrosion Rates',
        },
      },
    }
  }

  return (graphs)
}

export function getCmlCrGraph(cmlCrPages, cmlCrLabels, cmlCrColors) {
  const jsonObject = JSON.parse(cmlCrPages[0])
  const pageCount = jsonObject.pages.length
  const graphs = []
  const labels = JSON.parse(cmlCrLabels)
  const colors = JSON.parse(cmlCrColors)

  for(let page = 0; page < pageCount; page++) {
    const traces = []
    const cmlCount = jsonObject.pages[page].length
    for(let cmlOrder = 0; cmlOrder < cmlCount; cmlOrder++) {
      const cmlLabels = labels.labels[page][cmlOrder]
      const cmlColors = colors.colors[page][cmlOrder]

      const trace = {
        y: jsonObject.pages[page][cmlOrder],
        type: 'box',
        name: `CML ${cmlLabels}`,
        marker: {
          color: cmlColors,
        },
      }
      traces.push(trace)
    }

    graphs[`${page.toString()}`] = {
      traces,
      layout: {
        name: `CMLCrPage${page}`,
        title: 'CML Corrosion Rates',
        showlegend: false,
        hovermode: false,
        xaxis: {
          title: 'CML(s)',
        },
        yaxis: {
          title: 'Corrosion Rates',
        },
      },
    }
  }

  return (graphs)
}

export function getBoxWhiskerGraphs(jsonData, problemType, orderedCmls, orderedAcfs) {
  print(jsonData)
  const jsonObject = JSON.parse(jsonData[0])
  const orderedCmls2 = JSON.parse(orderedCmls)
  const orderedAcfs2 = JSON.parse(orderedAcfs)
  const jsonKeys = Object.keys(jsonObject)
  const traces = []
  const combinedRates = []
  jsonKeys.forEach((key) => {
    if(key === 'rate0') {
      jsonObject[key].forEach((val) => {
        combinedRates.push(...val)
      })
    }
  })
  const traceTemp = {
    y: combinedRates,
    opacity: 0.5,
    type: 'box',
    boxmean: true,
  }
  traceTemp.name = 'Circuit'
  traces.push(traceTemp)
  jsonKeys.forEach((key) => {
    if(key === 'rate0_acf') {
      jsonObject[key].forEach((val, index) => {
        const trace = {
          y: val,
          opacity: 0.5,
          type: 'box',
          boxmean: true,
        }
        if(typeof orderedAcfs2 !== 'undefined' && typeof orderedAcfs2[index] !== 'undefined') {
          trace.name = `ACF ${orderedAcfs2[index]}`
        } else {
          trace.name = `${key}[${index}]`
        }
        traces.push(trace)
      })
    }
  })
  jsonKeys.forEach((key) => {
    if(key === 'rate0') {
      jsonObject[key].forEach((val, index) => {
        const trace = {
          y: val,
          opacity: 0.5,
          type: 'box',
          boxmean: true,
        }
        if(typeof orderedCmls2 !== 'undefined' && typeof orderedCmls2[index] !== 'undefined') {
          trace.name = `CML ${orderedCmls2[index]}`
        } else {
          trace.name = `${key}[${index}]`
        }
        traces.push(trace)
      })
    }
  })
  const graph = {
    traces,
    layout: {
      name: 'boxWhisker',
      title: 'Corrosion Rate Box Whisker Plot',
      showlegend: false,
      xaxis: {
        title: 'CML ID',
      },
      yaxis: {},
    },
  }
  if(problemType === 'SIMP' || problemType === 'CS') {
    graph.layout.yaxis.title = 'Corrosion Rate (mils/yr)'
  } else {
    graph.layout.yaxis.title = 'Corrosion Rate (in/yr)'
  }
  return graph
}

export function getAvgDensityGraphs(jsonData, inIndex) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const jsonKeys = Object.keys(jsonObject)
  const intIndex = parseInt(inIndex, 10)
  const key = jsonKeys[intIndex]
  // jsonKeys.forEach((key) => {
  if(key === 'beta0' || key === 'rate0') {
    const traces = []
    jsonObject[key].forEach((val, index) => {
      const trace = {
        x: val,
        opacity: 0.5,
        type: 'histogram',
        name: `${key}[${index}]`,
      }
      traces.push(trace)
    })
    const graphId = `AvgDensity${key}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}`,
        title: `${key} Final Density Plot`,
        barmode: 'overlay',
        xaxis: {
          title: `${key}`,
        },
        yaxis: {
          title: 'Probability Density',
        },
      },
    }
  } else {
    const trace = [
      {
        x: jsonObject[key][0],
        opacity: 0.5,
        type: 'histogram',
        name: `${key}`,
      },
    ]
    const graphId = `AvgDensity${key}`
    graphs[graphId] = {
      traces: trace,
      layout: {
        name: `${key}`,
        title: `${key} Final Density Plot`,
        barmode: 'overlay',
        xaxis: {
          title: `${key}`,
        },
        yaxis: {
          title: 'Probability Density',
        },
      },
    }
  }
  return graphs
}

export function getDensityGraphs(jsonData, inValue) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const key = inValue.split('[')[0]
  let splitIndex = 0
  if(inValue.split('[').length > 1) {
    splitIndex = inValue.split('[')[1].split(']')[0]
  }
  // jsonKeys.forEach((key) => {
  if(key === 'beta0' || key === 'rate0') {
    // jsonObject[key].forEach((val, index) => {
    const traces = []
    for(let i = 0; i < jsonObject[key][splitIndex].length; i += 1) {
      const trace = {
        x: jsonObject[key][splitIndex][i],
        opacity: 0.5,
        type: 'histogram',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Densitygraph${key}${splitIndex}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}${splitIndex}`,
        title: `${key}[${splitIndex}] Density Plot`,
        barmode: 'overlay',
        xaxis: {
          title: `${key}[${splitIndex}]`,
        },
        yaxis: {
          title: 'Probability Density',
        },
      },
    }
  } else {
    const traces = []
    for(let i = 0; i < jsonObject[key][0].length; i += 1) {
      const trace = {
        x: jsonObject[key][0][i],
        opacity: 0.5,
        type: 'histogram',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Densitygraph${key}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}`,
        title: `${key} Density Plot`,
        barmode: 'overlay',
        xaxis: {
          title: `${key}`,
        },
        yaxis: {
          title: 'Probability Density',
        },
      },
    }
  }
  return graphs
}

export function getTracesPerChainGraph(jsonData, inValue) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const key = inValue.split('[')[0]
  let splitIndex = 0
  if(inValue.split('[').length > 1) {
    splitIndex = inValue.split('[')[1].split(']')[0]
  }
  // jsonKeys.forEach((key) => {
  if(key === 'beta0' || key === 'rate0') {
    // jsonObject[key].forEach((val, index) => {
    const traces = []
    for(let i = 0; i < jsonObject[key][splitIndex].length; i += 1) {
      const trace = {
        y: jsonObject[key][splitIndex][i],
        mode: 'lines',
        type: 'scatter',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Tracegraph${key}${splitIndex}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}${splitIndex}`,
        title: `${key}[${splitIndex}] Trace Plot`,
        xaxis: {
          title: 'Sample Number',
        },
        yaxis: {
          title: `${key}[${splitIndex}] Trace`,
        },
      },
    }
  } else {
    const traces = []
    for(let i = 0; i < jsonObject[key][0].length; i += 1) {
      const trace = {
        y: jsonObject[key][0][i],
        mode: 'lines',
        type: 'scatter',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Tracegraph${key}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}`,
        title: `${key} Trace Plot`,
        xaxis: {
          title: 'Sample Number',
        },
        yaxis: {
          title: `${key} Trace`,
        },
      },
    }
  }
  return graphs
}

export function getAutoCorrelationPerChainGraph(jsonData, key) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const traces = []
  for(let i = 0; i < jsonObject[key].yData.length; i += 1) {
    const trace = {
      x: jsonObject[key].xData,
      y: jsonObject[key].yData[i],
      mode: 'lines',
      type: 'scatter',
      name: `Chain ${i}`,
    }
    traces.push(trace)
  }
  const graphId = `AutoCorrelationGraph${key}`
  graphs[graphId] = {
    traces,
    layout: {
      name: `${key}`,
      title: `${key} Auto Correlation Plot`,
      xaxis: {
        title: 'Lag',
      },
      yaxis: {
        title: `${key} Auto Correlation`,
      },
    },
  }
  return graphs
}

export function getRunningMeanPerChainGraph(jsonData, inValue) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const key = inValue.split('[')[0]
  let splitIndex = 0
  if(inValue.split('[').length > 1) {
    splitIndex = inValue.split('[')[1].split(']')[0]
  }
  // jsonKeys.forEach((key) => {
  if(key === 'beta0' || key === 'rate0') {
    // jsonObject[key].forEach((val, index) => {
    const traces = []
    for(let i = 0; i < jsonObject[key][splitIndex].length; i += 1) {
      const trace = {
        y: jsonObject[key][splitIndex][i],
        mode: 'lines',
        type: 'scatter',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Runninggraph${key}${splitIndex}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}${splitIndex}`,
        title: `${key}[${splitIndex}] Running Mean Plot`,
        xaxis: {
          title: 'Sample Number',
        },
        yaxis: {
          title: `${key}[${splitIndex}] Running Mean`,
        },
      },
    }
  } else {
    const traces = []
    for(let i = 0; i < jsonObject[key][0].length; i += 1) {
      const trace = {
        y: jsonObject[key][0][i],
        mode: 'lines',
        type: 'scatter',
        name: `Chain ${i}`,
      }
      traces.push(trace)
    }
    const graphId = `Runninggraph${key}`
    graphs[graphId] = {
      traces,
      layout: {
        name: `${key}`,
        title: `${key} Running Mean Plot`,
        xaxis: {
          title: 'Sample Number',
        },
        yaxis: {
          title: `${key} Running Mean`,
        },
      },
    }
  }
  return graphs
}

export function getTraces(jsonData) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const jsonKeys = Object.keys(jsonObject)
  jsonKeys.forEach((key) => {
    if(key === 'beta0' || key === 'rate0') {
      const traces = []
      jsonObject[key].forEach((val, index) => {
        const trace = {
          y: val,
          mode: 'lines',
          type: 'scatter',
          name: `${key}[${index}]`,
        }
        traces.push(trace)
      })
      const graphId = `graph${key}`
      graphs[graphId] = {
        traces,
        layout: {
          name: `${key}`,
          title: `${key} Trace Plot`,
          barmode: 'overlay',
          xaxis: {
            title: 'Sample Number',
          },
          yaxis: {
            title: `${key} Trace`,
          },
        },
      }
    } else {
      const trace = [
        {
          y: jsonObject[key][0],
          mode: 'lines',
          type: 'scatter',
          name: `${key}`,
        },
      ]
      const graphId = `graph${key}`
      graphs[graphId] = {
        traces: trace,
        layout: {
          name: `${key}`,
          title: `${key} Trace Plot`,
          barmode: 'overlay',
          xaxis: {
            title: 'Sample Number',
          },
          yaxis: {
            title: `${key} Trace`,
          },
        },
      }
    }
  })
  return graphs
}

export function getRunningMeanGraphs(jsonData) {
  const jsonObject = JSON.parse(jsonData[0])
  const graphs = {}
  const jsonKeys = Object.keys(jsonObject)
  jsonKeys.forEach((key) => {
    if(key === 'beta0' || key === 'rate0') {
      const traces = []
      jsonObject[key].forEach((val, index) => {
        const trace = {
          y: val,
          mode: 'lines',
          type: 'scatter',
          name: `${key}[${index}]`,
        }
        traces.push(trace)
      })
      const graphId = `graph${key}`
      graphs[graphId] = {
        traces,
        layout: {
          name: `${key}`,
          title: `${key} Running Mean Plot`,
          xaxis: {
            title: 'Sample Number',
          },
          yaxis: {
            title: `${key} Running Mean`,
          },
        },
      }
    } else {
      const trace = [
        {
          y: jsonObject[key][0],
          mode: 'lines',
          type: 'scatter',
          name: `${key}`,
        },
      ]
      const graphId = `graph${key}`
      graphs[graphId] = {
        traces: trace,
        layout: {
          name: `${key}`,
          title: `${key} Running Mean Plot`,
          xaxis: {
            title: 'Sample Number',
          },
          yaxis: {
            title: `${key} Running Mean`,
          },
        },
      }
    }
  })
  return graphs
}

export function getResidual(median, lower, upper, problemType) {
  const trace = [
    {
      y: median,
      mode: 'markers',
      type: 'scatter',
      error_y: {
        type: 'data',
        symmetric: false,
        array: upper,
        arrayminus: lower,
      },
      name: 'Residual',
    },
  ]
  const graph = {
    traces: trace,
    layout: {
      name: 'residualPlot',
      title: 'Residual Plot',
      xaxis: {
        title: 'Observed Thickness Reading',
      },
      yaxis: {},
    },
  }
  if(problemType === 'SIMP' || problemType === 'CS') {
    graph.layout.yaxis.title = 'Residual (Measured - Predicted) [mils]'
  } else {
    graph.layout.yaxis.title = 'Residual (Measured - Predicted) [in]'
  }
  return graph
}

export function getRiskMatrixData(riskMatrixString) {
  const riskMatrixJson = JSON.parse(riskMatrixString)
  const graph = {
    traces: riskMatrixJson.data,
    layout: riskMatrixJson.layout,
  }

  return graph
}

export function assignGridSize(cmlCount) {
  let gridSize = 1
  if(cmlCount > 6) {
    gridSize = 9 // rows = 3, columns = 3
  } else if(cmlCount > 4) {
    gridSize = 6 // rows = 2, columns = 3
  } else if(cmlCount > 3) {
    gridSize = 4 // rows = 2, columns = 2
  } else if(cmlCount > 2) {
    gridSize = 3 // rows = 1, columns = 3
  } else if(cmlCount > 1) {
    gridSize = 2 // rows = 1, columns = 2
  }

  return gridSize
}

export function validateNextTaDate(nextTaDate) {
  let newNextTaDate

  if(typeof nextTaDate === 'undefined') {
    // If no date, add a year to the current/analysis date
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() + 1, currentDate.getMonth(), currentDate.getDay())
    newNextTaDate = currentDate.toLocaleDateString()
  } else {
    newNextTaDate = nextTaDate
  }

  return newNextTaDate
}

export function validateTurnaroundFrequency(turnaroundFrequency) {
  let newTurnaroundFrequency

  if(typeof turnaroundFrequency === 'undefined') {
    newTurnaroundFrequency = 4
  } else {
    newTurnaroundFrequency = turnaroundFrequency
  }

  return newTurnaroundFrequency
}

export function getPlotlyTracesLayout(fullPlotJson) {
  const fullPlotJsonParsed = JSON.parse(fullPlotJson)
  const plotComponents = {
    traces: fullPlotJsonParsed.data,
    layout: fullPlotJsonParsed.layout,
  }

  return plotComponents
}

export function getCmlPofPlotData(cmlPofPlotJson) {
  const cmlPofJson = JSON.parse(cmlPofPlotJson)
  const cmlPofPlot = {
    traces: cmlPofJson.data,
    layout: cmlPofJson.layout,
  }

  return cmlPofPlot
}
