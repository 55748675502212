import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'

import { getPlotlyTracesLayout } from '../lib/getResultCases.js'

const CorrosionRateDensityR = ({ results, crDensityPlotJson }) => {
  const crDensityPlotData = getPlotlyTracesLayout(crDensityPlotJson)

  return (
    <div className="cf w-100">
      {results.crDensityPlotJson
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={crDensityPlotData.traces} layout={crDensityPlotData.layout} />
            </div>
            <div>
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

CorrosionRateDensityR.propTypes = {
  results: PropTypes.object,
  crDensityPlotJson: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  crDensityPlotJson: state.workflow.response ? state.workflow.response.results.crDensityPlotJson : undefined,
})

export default connect(mapStateToProps, null)(CorrosionRateDensityR)
