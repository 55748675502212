export default [
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'censoredReadingsTable',
    default: [
      ['0.125', 2, 4],
      ['0.25', 2, 4],
      ['0.375', 2, 4],
      ['0.5', 2, 4],
      ['0.75', 2, 8],
      ['1', 2, 8],
      ['1.25', 2, 8],
      ['1.5', 2, 8],
      ['2', 4, 20],
      ['2.5', 4, 20],
      ['3', 4, 20],
      ['3.5', 4, 20],
      ['4', 4, 20],
      ['5', 4, 20],
      ['6', 4, 20],
      ['8', 4, 20],
      ['10', 4, 20],
      ['12', 4, 20],
      ['14', 4, 20],
      ['16', 4, 20],
      ['20', 4, 20],
      ['24', 4, 20],
      ['26', 4, 20],
      ['28', 4, 20],
      ['30', 4, 20],
      ['32', 4, 20],
      ['34', 4, 20],
      ['36', 4, 20],
      ['42', 4, 20],
      ['48', 4, 20],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'errorMultipliersTable',
    default: [
      ['CAP', 1.24, 1.20],
      ['CPLG', 1.10, 1.15],
      ['ELL', 1.24, 1.20],
      ['HVYWALL', 1.25, 1.20],
      ['NIP', 1.00, 1.15],
      ['PIPE', 1.00, 1.00],
      ['RED', 1.58, 1.75],
      ['TEE', 1.54, 1.65],
      ['TNIP', 1.00, 1.15],
      ['TPIPE', 1.00, 1.00],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'pipeScheduleTable',
    default: [
      ['0.125', '10', 0.049, 0.051, 0.0035, 0],
      ['0.125', '40', 0.068, 0.071, 0.0035, 0],
      ['0.125', '80', 0.095, 0.1, 0.0035, 1],
      ['0.25', '10', 0.065, 0.068, 0.0035, 0],
      ['0.25', '40', 0.088, 0.092, 0.0035, 0],
      ['0.25', '80', 0.119, 0.125, 0.0035, 1],
      ['0.375', '10', 0.065, 0.068, 0.0035, 0],
      ['0.375', '40', 0.091, 0.096, 0.0035, 0],
      ['0.375', '80', 0.126, 0.132, 0.0035, 1],
      ['0.5', '40', 0.109, 0.114, 0.0035, 0],
      ['0.5', '80', 0.147, 0.154, 0.0035, 0],
      ['0.5', '160', 0.187, 0.196, 0.0035, 1],
      ['0.5', 'XXS', 0.294, 0.309, 0.0035, 0],
      ['0.5', '150LWN', 0.375, 0.394, 0.0035, 0],
      ['0.5', '300LWN', 0.5, 0.525, 0.0035, 0],
      ['0.75', '5S', 0.065, 0.068, 0.0035, 0],
      ['0.75', '40', 0.113, 0.119, 0.0035, 0],
      ['0.75', '80', 0.154, 0.162, 0.0035, 0],
      ['0.75', '160', 0.219, 0.23, 0.0035, 1],
      ['0.75', 'XXS', 0.308, 0.323, 0.0035, 0],
      ['0.75', '150LWN1', 0.437, 0.459, 0.0035, 0],
      ['0.75', '150LWN2', 0.5, 0.525, 0.0035, 0],
      ['0.75', '6000CPLG', 0.532, 0.562, 0.0035, 0],
      ['0.75', '300LWN', 0.562, 0.59, 0.0035, 0],
      ['1', '40', 0.133, 0.14, 0.0035, 0],
      ['1', '80', 0.179, 0.188, 0.0035, 0],
      ['1', '160', 0.25, 0.263, 0.0035, 1],
      ['1', 'XXS', 0.358, 0.376, 0.0035, 0],
      ['1', '150LWN1', 0.437, 0.459, 0.0035, 0],
      ['1', '150LWN2', 0.5, 0.525, 0.0035, 0],
      ['1', '6000CPLG', 0.532, 0.562, 0.0035, 0],
      ['1', '300LWN', 0.562, 0.59, 0.0035, 0],
      ['1.25', '40', 0.14, 0.147, 0.0035, 0],
      ['1.25', '80', 0.191, 0.201, 0.0035, 0],
      ['1.25', '160', 0.25, 0.263, 0.0035, 1],
      ['1.25', 'XXS', 0.382, 0.401, 0.0035, 0],
      ['1.25', '150LWN1', 0.5, 0.525, 0.0035, 0],
      ['1.25', '150LWN2', 0.562, 0.59, 0.0035, 0],
      ['1.25', '300LWN', 0.625, 0.656, 0.0035, 0],
      ['1.5', '40', 0.145, 0.152, 0.0035, 0],
      ['1.5', '80', 0.2, 0.21, 0.0035, 1],
      ['1.5', '160', 0.281, 0.295, 0.0035, 0],
      ['1.5', 'XXS', 0.4, 0.42, 0.0035, 0],
      ['1.5', '150LWN1', 0.5, 0.525, 0.0035, 0],
      ['1.5', '150LWN2', 0.562, 0.59, 0.0035, 0],
      ['1.5', '300LWN', 0.625, 0.656, 0.0035, 0],
      ['2', '40', 0.154, 0.162, 0.0035, 0],
      ['2', '80', 0.218, 0.229, 0.0035, 1],
      ['2', '160', 0.343, 0.36, 0.0035, 0],
      ['2', 'XXS', 0.436, 0.458, 0.0035, 0],
      ['2', '150LWN1', 0.562, 0.59, 0.0035, 0],
      ['2', 'LWN', 0.625, 0.656, 0.0035, 0],
      ['2.5', '10', 0.12, 0.126, 0.0035, 0],
      ['2.5', '40', 0.203, 0.213, 0.0035, 1],
      ['2.5', '80', 0.276, 0.29, 0.0035, 0],
      ['2.5', '160', 0.375, 0.394, 0.0035, 0],
      ['2.5', 'XXS', 0.552, 0.58, 0.0035, 0],
      ['3', '10', 0.12, 0.126, 0.0035, 0],
      ['3', '40', 0.216, 0.227, 0.0035, 1],
      ['3', '80', 0.3, 0.315, 0.0035, 0],
      ['3', '160', 0.437, 0.459, 0.0035, 0],
      ['3', 'XXS', 0.6, 0.63, 0.0035, 0],
      ['3.5', '10', 0.12, 0.126, 0.0035, 0],
      ['3.5', '40', 0.226, 0.237, 0.0035, 1],
      ['3.5', '80', 0.318, 0.334, 0.0035, 0],
      ['4', '10', 0.12, 0.126, 0.0035, 0],
      ['4', '40', 0.237, 0.249, 0.0035, 1],
      ['4', '80', 0.337, 0.354, 0.0035, 0],
      ['4', '120', 0.437, 0.459, 0.0035, 0],
      ['4', '160', 0.531, 0.558, 0.0035, 0],
      ['4', 'XXS', 0.674, 0.708, 0.0035, 0],
      ['4', 'XXXS', 1, 1.05, 0.0035, 0],
      ['5', '10', 0.134, 0.141, 0.0035, 0],
      ['5', '40', 0.258, 0.271, 0.0035, 1],
      ['5', '80', 0.375, 0.394, 0.0035, 0],
      ['5', '120', 0.5, 0.525, 0.0035, 0],
      ['5', '160', 0.625, 0.656, 0.0035, 0],
      ['5', 'XXS', 0.75, 0.788, 0.0035, 0],
      ['6', '10', 0.134, 0.141, 0.0035, 0],
      ['6', '40', 0.28, 0.294, 0.0035, 1],
      ['6', '80', 0.432, 0.454, 0.0035, 0],
      ['6', '120', 0.562, 0.59, 0.0035, 0],
      ['6', '160', 0.718, 0.754, 0.0035, 0],
      ['6', 'XXS', 0.864, 0.907, 0.00385, 0],
      ['8', '20', 0.25, 0.263, 0.0035, 0],
      ['8', '30', 0.277, 0.291, 0.0035, 0],
      ['8', '40', 0.322, 0.338, 0.0035, 1],
      ['8', '60', 0.406, 0.426, 0.0035, 0],
      ['8', '80', 0.5, 0.525, 0.0042, 0],
      ['8', '120', 0.718, 0.754, 0.0049, 0],
      ['8', '140', 0.812, 0.853, 0.00525, 0],
      ['8', 'XXS', 0.875, 0.919, 0.00525, 0],
      ['8', '160', 0.906, 0.951, 0.0056, 0],
      ['10', '20', 0.25, 0.263, 0.0035, 0],
      ['10', '30', 0.307, 0.322, 0.00385, 0],
      ['10', '40', 0.365, 0.383, 0.0042, 1],
      ['10', 'XS', 0.5, 0.525, 0.00525, 0],
      ['10', '80', 0.593, 0.623, 0.0056, 0],
      ['10', '100', 0.718, 0.754, 0.00595, 0],
      ['10', '120', 0.843, 0.885, 0.00665, 0],
      ['10', '140', 1, 1.05, 0.00735, 0],
      ['10', '160', 1.125, 1.181, 0.0077, 0],
      ['12', '20', 0.25, 0.263, 0.0042, 0],
      ['12', '30', 0.33, 0.347, 0.0049, 0],
      ['12', 'STD', 0.375, 0.394, 0.00525, 1],
      ['12', '40', 0.406, 0.426, 0.0056, 0],
      ['12', 'XS', 0.5, 0.525, 0.00595, 0],
      ['12', '60', 0.562, 0.59, 0.0063, 0],
      ['12', '80', 0.687, 0.721, 0.007, 0],
      ['12', '100', 0.843, 0.885, 0.00805, 0],
      ['12', '120', 1, 1.05, 0.00875, 0],
      ['12', '140', 1.125, 1.181, 0.0091, 0],
      ['12', '160', 1.312, 1.378, 0.0098, 0],
      ['14', '10', 0.25, 0.263, 0.0049, 0],
      ['14', '20', 0.312, 0.328, 0.0056, 0],
      ['14', 'STD', 0.375, 0.394, 0.0063, 1],
      ['14', 'XS', 0.5, 0.525, 0.007, 0],
      ['14', '60', 0.593, 0.623, 0.0077, 0],
      ['14', '80', 0.75, 0.788, 0.00875, 0],
      ['14', '100', 0.937, 0.984, 0.0098, 0],
      ['14', '120', 1.093, 1.148, 0.0105, 0],
      ['14', '140', 1.25, 1.313, 0.0112, 0],
      ['14', '160', 1.406, 1.476, 0.0119, 0],
      ['16', '10', 0.25, 0.263, 0.0056, 0],
      ['16', '20', 0.312, 0.328, 0.0063, 0],
      ['16', 'STD', 0.375, 0.394, 0.007, 1],
      ['16', '40', 0.5, 0.525, 0.00805, 0],
      ['16', '60', 0.656, 0.689, 0.00945, 0],
      ['16', '80', 0.843, 0.885, 0.0105, 0],
      ['16', '100', 1.031, 1.083, 0.01155, 0],
      ['16', '120', 1.218, 1.279, 0.0126, 0],
      ['16', '160', 1.437, 1.509, 0.01365, 0],
      ['16', '160', 1.593, 1.673, 0.01435, 0],
      ['18', '10', 0.25, 0.263, 0.0063, 0],
      ['18', '20', 0.312, 0.328, 0.00735, 0],
      ['18', 'STD', 0.375, 0.394, 0.00805, 1],
      ['18', 'XS', 0.5, 0.525, 0.0091, 0],
      ['18', '40', 0.562, 0.59, 0.0098, 0],
      ['18', '80', 0.75, 0.788, 0.0112, 0],
      ['18', '80', 0.937, 0.984, 0.0126, 0],
      ['18', '100', 1.156, 1.214, 0.014, 0],
      ['18', '120', 1.375, 1.444, 0.01505, 0],
      ['18', '140', 1.562, 1.64, 0.0161, 0],
      ['18', '160', 1.781, 1.87, 0.01715, 0],
      ['20', '10', 0.25, 0.263, 0.00735, 0],
      ['20', 'STD', 0.375, 0.394, 0.00875, 1],
      ['20', 'XS', 0.5, 0.525, 0.01015, 0],
      ['20', '40', 0.593, 0.623, 0.0112, 0],
      ['20', '60', 0.812, 0.853, 0.01295, 0],
      ['20', '80', 1.031, 1.083, 0.0147, 0],
      ['20', '100', 1.281, 1.345, 0.0161, 0],
      ['20', '120', 1.5, 1.575, 0.0175, 0],
      ['20', '140', 1.75, 1.838, 0.0189, 0],
      ['20', '160', 1.968, 2.066, 0.0203, 0],
      ['24', '10', 0.25, 0.263, 0.00875, 0],
      ['24', 'STD', 0.375, 0.394, 0.0105, 1],
      ['24', 'XS', 0.5, 0.525, 0.01225, 0],
      ['24', '30', 0.562, 0.59, 0.01295, 0],
      ['24', '40', 0.687, 0.721, 0.01435, 0],
      ['24', '60', 0.968, 1.016, 0.01715, 0],
      ['24', '80', 1.218, 1.279, 0.0189, 0],
      ['24', '100', 1.531, 1.608, 0.02135, 0],
      ['24', '120', 1.812, 1.903, 0.0231, 0],
      ['24', '140', 2.062, 2.165, 0.02485, 0],
      ['24', '160', 2.343, 2.46, 0.02625, 0],
      ['26', '10', 0.312, 0.328, 0.0105, 0],
      ['26', 'STD', 0.375, 0.394, 0.01155, 1],
      ['26', '80', 0.5, 0.525, 0.0133, 0],
      ['28', '10', 0.312, 0.328, 0.0112, 0],
      ['28', '30', 0.375, 0.394, 0.01225, 0],
      ['28', '40', 0.5, 0.525, 0.01435, 0],
      ['28', 'STD', 0.5, 0.525, 0.01435, 1],
      ['30', '10', 0.312, 0.328, 0.0119, 0],
      ['30', '40', 0.375, 0.394, 0.0133, 0],
      ['30', 'STD', 0.5, 0.525, 0.0154, 1],
      ['30', '30', 0.625, 0.656, 0.01715, 0],
      ['32', '10', 0.312, 0.328, 0.01295, 0],
      ['32', 'STD', 0.375, 0.394, 0.014, 1],
      ['32', '80', 0.5, 0.525, 0.0161, 0],
      ['32', '30', 0.625, 0.656, 0.0182, 0],
      ['34', '10', 0.312, 0.328, 0.01365, 0],
      ['34', 'STD', 0.375, 0.394, 0.01505, 1],
      ['34', '80', 0.5, 0.525, 0.01715, 0],
      ['34', '30', 0.625, 0.656, 0.01925, 0],
      ['36', '10', 0.312, 0.328, 0.01435, 0],
      ['36', 'STD', 0.375, 0.394, 0.01575, 1],
      ['36', '80', 0.5, 0.525, 0.0182, 0],
      ['36', '30', 0.625, 0.656, 0.0203, 0],
      ['42', 'STD', 0.375, 0.394, 0.01855, 1],
      ['42', '80', 0.5, 0.525, 0.02135, 0],
      ['48', 'STD', 0.375, 0.394, 0.021, 1],
      ['48', '80', 0.5, 0.525, 0.0245, 0],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'cmlMeasurementThicknessTable',
    default: [
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
      ['', '', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'cmlMeasurementThicknessTable2',
    default: [
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'cmlDataAndLabelsTable',
    default: [
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'cmlDataAndLabelsTable2',
    default: [
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'cmlDataAndLabelsTable3',
    default: [
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
      ['', '', '', '', '', '', '', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 2,
    fieldName: 'acfInputTable',
    default: [
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'cmlReplacementDatesTable',
    default: [
      ['', ''],
    ],
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'nextTaDate',
    valueType: 0,
    fieldName: 'nextTaDate',
    default: undefined,
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'turnaroundFrequency',
    valueType: 1,
    fieldName: 'turnaroundFrequency',
    default: 4,
    min: 1,
    max: 24,
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'circuit',
    valueType: 0,
    fieldName: 'circuit',
    default: 'Circuit 001',
    type: 'input',
    initializer: undefined,
    label: 'Circuit Name',
  },
  {
    keyword: 'model_run_type',
    valueType: 0,
    fieldName: 'model_run_type',
    default: 'standard',
    type: 'input',
    initializer: undefined,
    label: 'Model Run Type',
  },
  {
    keyword: 'use_linear_rates_flag',
    valueType: 1,
    fieldName: 'use_linear_rates_flag',
    default: 1,
    type: 'input',
    initializer: undefined,
    label: 'Use Linear Rates',
  },
  {
    keyword: 'use_piecewise_rates_flag',
    valueType: 1,
    fieldName: 'use_piecewise_rates_flag',
    default: 1,
    type: 'input',
    initializer: undefined,
    label: 'Use Piecewise Rates',
  },
  {
    keyword: 'use_unmonitored_comps',
    valueType: 0,
    fieldName: 'use_unmonitored_comps',
    default: 'TRUE',
    type: 'input',
    initializer: undefined,
    label: 'Use Monitored Components',
  },
  {
    keyword: 'use_weighted_readings',
    valueType: 0,
    fieldName: 'use_weighted_readings',
    default: 'TRUE',
    type: 'input',
    initializer: undefined,
    label: 'Use Weighted Readings',
  },
  {
    keyword: 'use_convergence',
    valueType: 0,
    fieldName: 'use_convergence',
    default: 'TRUE',
    type: 'input',
    initializer: undefined,
    label: 'Use Convergence',
  },
  {
    keyword: 'project_with_meas_err',
    valueType: 0,
    fieldName: 'project_with_meas_err',
    default: 'TRUE',
    type: 'input',
    initializer: undefined,
    label: 'Project with Error',
  },
  {
    keyword: 'consequence_basis',
    valueType: 0,
    fieldName: 'consequence_basis',
    default: 'financial',
    type: 'input',
    initializer: undefined,
    label: 'Consequence Basis',
  },
  {
    keyword: 'financial_risk_action_level',
    valueType: 2,
    fieldName: 'financial_risk_action_level',
    default: 10000,
    min: 0,
    units: {
      USCustomary: '$',
      Metric: '$',
    },
    type: 'input',
    initializer: undefined,
    label: 'Financial Risk Action Level',
  },
  {
    keyword: 'consequence_financial',
    valueType: 2,
    fieldName: 'consequence_financial',
    default: 100000,
    min: 0,
    units: {
      USCustomary: '$',
      Metric: '$',
    },
    type: 'input',
    initializer: undefined,
    label: 'Financial Consequence',
  },
  {
    keyword: 'consequence_area',
    valueType: 2,
    fieldName: 'consequence_area',
    default: 100,
    min: 0,
    type: 'input',
    initializer: undefined,
    label: 'Area Consequence',
  },
  {
    keyword: 'consequence_class',
    valueType: 0,
    fieldName: 'consequence_class',
    default: 'C',
    type: 'input',
    initializer: undefined,
    label: 'Consequence Class',
  },
  {
    keyword: 'priorCrMax',
    valueType: 2,
    fieldName: 'priorCrMax',
    min: {
      USCustomary: 0.0,
      Metric: 0.0,
    },
    default: {
      USCustomary: 20.0,
      Metric: 0.5,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Prior CR Max',
  },
  {
    keyword: 'thinning_dm',
    valueType: 1,
    fieldName: 'thinning_dm',
    default: '2',
    type: 'input',
    initializer: undefined,
    label: 'Thinning DM',
  },
  {
    keyword: 'consequence_area_risk_action_level',
    valueType: 2,
    fieldName: 'consequence_area_risk_action_level',
    default: 35,
    min: 0,
    type: 'input',
    initializer: undefined,
    label: 'Area Risk Action Level',
  },
  {
    keyword: 'pof_risk_action_target',
    valueType: 2,
    fieldName: 'pof_risk_action_target',
    default: 0.1,
    min: 0,
    max: 0.5,
    type: 'input',
    initializer: undefined,
    label: 'Area Risk Action Level',
  },
  {
    keyword: 'accepted_measurement_threshold',
    valueType: 2,
    fieldName: 'accepted_measurement_threshold',
    type: 'input',
    default: {
      USCustomary: 0.015,
      Metric: 0.4,
    },
    min: {
      USCustomary: 0.005,
      Metric: 0.125,
    },
    max: {
      USCustomary: 0.03,
      Metric: 0.8,
    },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    initializer: undefined,
    label: 'Measurement Error Threshold',
  },
  {
    keyword: 'measurement_error_noisy_threshold',
    valueType: 2,
    fieldName: 'measurement_error_noisy_threshold',
    type: 'input',
    default: {
      USCustomary: 0.03,
      Metric: 0.8,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    initializer: undefined,
    label: 'Noisy Error Threshold',
  },
  {
    keyword: 'measurement_error_noise_quantile',
    valueType: 2,
    fieldName: 'measurement_error_noise_quantile',
    type: 'input',
    default: 0.9,
    min: 0.5,
    max: 1,
    initializer: undefined,
    label: 'Noisy Error Threshold',
  },
  {
    keyword: 'problemType',
    valueType: 0,
    fieldName: 'problemType',
    default: 'R',
    type: 'input',
    initializer: undefined,
    label: 'Problem Type',
  },
  {
    keyword: 'priorConfidence',
    valueType: 0,
    fieldName: 'priorConfidence',
    default: 'low',
    type: 'input',
    initializer: undefined,
    label: 'Prior Corrosion Rate Confidence',
  },
  {
    keyword: 'corrosionRateForm',
    valueType: 0,
    fieldName: 'corrosionRateForm',
    default: 'Constant',
    type: 'input',
    initializer: undefined,
    label: 'Corrosion Rate Form',
  },
  {
    keyword: 'weightSteepnessValue',
    valueType: 2,
    fieldName: 'weightSteepnessValue',
    default: {
      USCustomary: 1,
      Metric: 1,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.01,
    },
    units: {
      USCustomary: 'yr',
      Metric: 'yr',
    },
    initializer: undefined,
    type: 'input',
    label: 'Weight Steepness Value',
  },
  {
    keyword: 'weightMidValue',
    valueType: 2,
    fieldName: 'weightMidValue',
    default: {
      USCustomary: 18,
      Metric: 18,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.01,
    },
    initializer: undefined,
    units: {
      USCustomary: 'yr',
      Metric: 'yr',
    },
    type: 'input',
    label: 'Weight Mid Value',
  },
  {
    keyword: 'weightMaxValue',
    valueType: 2,
    fieldName: 'weightMaxValue',
    default: {
      USCustomary: 10,
      Metric: 10,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.01,
    },
    initializer: undefined,
    units: {
      USCustomary: 'yr',
      Metric: 'yr',
    },
    type: 'input',
    label: 'Weight Max Value',
  },
  {
    keyword: 'weightBaseValue',
    valueType: 2,
    fieldName: 'weightBaseValue',
    default: {
      USCustomary: 1,
      Metric: 1,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.01,
    },
    initializer: undefined,
    units: {
      USCustomary: 'yr',
      Metric: 'yr',
    },
    type: 'input',
    label: 'Weight Base Value',
  },
  {
    keyword: 'visualInitial',
    valueType: 2,
    fieldName: 'visualInitial',
    default: {
      USCustomary: 0.001,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Visual Inspection Initial Condition',
  },
  {
    keyword: 'sigma1SdInitial',
    valueType: 2,
    fieldName: 'sigma1SdInitial',
    default: {
      USCustomary: 0.000001,
      Metric: 0.0000254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Sigma 1 SD Initial Condition',
  },
  {
    keyword: 'sigma1MuInitial',
    valueType: 2,
    fieldName: 'sigma1MuInitial',
    default: {
      USCustomary: 0.001,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Sigma 1 Mu Initial Condition',
  },
  {
    keyword: 'rate2MuInitial',
    valueType: 2,
    fieldName: 'rate2MuInitial',
    default: {
      USCustomary: 0.00000001,
      Metric: 0.000000254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Rate 2 Mu Initial',
  },
  {
    keyword: 'rate2SdInitial',
    valueType: 2,
    fieldName: 'rate2SdInitial',
    default: {
      USCustomary: 0.00001,
      Metric: 0.000254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Rate 2 SD Initial',
  },
  {
    keyword: 'rate1SdInitial',
    valueType: 2,
    fieldName: 'rate1SdInitial',
    default: {
      USCustomary: 0.000001,
      Metric: 0.0000254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Rate 1 SD Initial',
  },
  {
    keyword: 'rate0SdInitial',
    valueType: 2,
    fieldName: 'rate0SdInitial',
    default: {
      USCustomary: 0.000001,
      Metric: 0.0000254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Rate 0 SD Initial',
  },
  {
    keyword: 'projectionsCredibleInterval',
    valueType: 2,
    fieldName: 'projectionsCredibleInterval',
    default: 90,
    min: 0,
    max: 100,
    initializer: undefined,
    type: 'input',
    label: 'cred Interval Projections',
  },
  {
    keyword: 'evtFlag',
    valueType: 0,
    fieldName: 'evtFlag',
    default: 'evt',
    initializer: undefined,
    type: 'input',
    label: 'Multiple Readings Method',
  },
  {
    keyword: 'vtInterval',
    valueType: 1,
    fieldName: 'vtInterval',
    default: 5,
    min: 0,
    max: 100,
    initializer: undefined,
    units: {
      USCustomary: 'yrs',
      Metric: 'yrs',
    },
    type: 'input',
    label: 'Visual Inspection Frequency',
  },
  {
    keyword: 'nChains',
    valueType: 1,
    fieldName: 'nChains',
    default: 4,
    initializer: undefined,
    type: 'input',
    label: 'Number of Chains',
  },
  {
    keyword: 'whichCircuitPriorForm',
    valueType: 0,
    fieldName: 'whichCircuitPriorForm',
    default: 'constant',
    initializer: undefined,
    type: 'input',
    label: 'Circuit Corrosion Rate Prior Specify Forma',
  },
  {
    keyword: 'numberOfBurnInSteps',
    valueType: 1,
    fieldName: 'numberOfBurnInSteps',
    default: 1000,
    min: 0,
    initializer: undefined,
    type: 'input',
    label: 'Number of Burn In Steps',
  },
  {
    keyword: 'numberOfAdaptiveSteps',
    valueType: 1,
    fieldName: 'numberOfAdaptiveSteps',
    default: 1000,
    min: 0,
    initializer: undefined,
    type: 'input',
    label: 'Number of Adaptive Steps',
  },
  {
    keyword: 'numberOfSamplesToThin',
    valueType: 1,
    fieldName: 'numberOfSamplesToThin',
    default: 10,
    min: 0,
    initializer: undefined,
    type: 'input',
    label: 'Number of Samples to Thin',
  },
  {
    keyword: 'numberOfTotalSamples',
    valueType: 1,
    fieldName: 'numberOfTotalSamples',
    default: 10000,
    min: 1000,
    initializer: undefined,
    type: 'input',
    label: 'Number of Total Samples',
  },
  {
    keyword: 'priorCrMultiplier',
    valueType: 2,
    fieldName: 'priorCrMultiplier',
    default: 1.5,
    min: 0.1,
    initializer: undefined,
    type: 'input',
    label: 'Prior Corrosion Rate Multiplier',
  },
  {
    keyword: 'censoredMaxOffset',
    valueType: 2,
    fieldName: 'censoredMaxOffset',
    default: {
      USCustomary: 0.015,
      Metric: 0.3,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Censored Max Value Offset',
  },
  {
    keyword: 'minCmlLossOffset',
    valueType: 2,
    fieldName: 'minCmlLossOffset',
    default: {
      USCustomary: 0.001,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'in',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Min CML Loss Offset',
  },
  {
    keyword: 'priorCrMean',
    valueType: 2,
    fieldName: 'priorCrMean',
    default: {
      USCustomary: 5,
      Metric: 0.127,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Prior Corrosion Rate Mean',
  },
  {
    keyword: 'priorCrMeanSd',
    valueType: 2,
    fieldName: 'priorCrMeanSd',
    default: {
      USCustomary: 2.5,
      Metric: 0.0635,
    },
    min: {
      USCustomary: 0.001,
      Metric: 0.000254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Prior Corrosion Rate Mean Sd',
  },
  {
    keyword: 'priorCrMeanRatio',
    valueType: 2,
    fieldName: 'priorCrMeanRatio',
    default: {
      USCustomary: 0.5,
      Metric: 0.5,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    max: {
      USCustomary: 1,
      Metric: 1,
    },
    initializer: undefined,
    type: 'input',
    label: 'Prior Corrosion Rate Mean Sd Ratio from Mean',
  },
  {
    keyword: 'priorCrSd',
    valueType: 2,
    fieldName: 'priorCrSd',
    default: {
      USCustomary: 1,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Prior Corrosion Rate SD',
  },
  {
    keyword: 'priorCrSdSd',
    valueType: 2,
    fieldName: 'priorCrSdSd',
    default: {
      USCustomary: 0.5,
      Metric: 0.0127,
    },
    min: {
      USCustomary: 0.001,
      Metric: 0.000254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil/yr',
      Metric: 'mm/yr',
    },
    type: 'input',
    label: 'Prior Corrosion Rate SD Sd',
  },
  {
    keyword: 'priorCrSdRatio',
    valueType: 2,
    fieldName: 'priorCrSdRatio',
    default: {
      USCustomary: 0.5,
      Metric: 0.5,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    max: {
      USCustomary: 1,
      Metric: 1,
    },
    initializer: undefined,
    type: 'input',
    label: 'Prior Corrosion Rate SD Sd Ratio from Mean',
  },
  {
    keyword: 'priorMeasErrorMean',
    valueType: 2,
    fieldName: 'priorMeasErrorMean',
    default: {
      USCustomary: 5,
      Metric: 0.127,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Measurement Error Mean',
  },
  {
    keyword: 'priorMeasErrorSd',
    valueType: 2,
    fieldName: 'priorMeasErrorSD',
    default: {
      USCustomary: 5,
      Metric: 0.127,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Measurement Error SD',
  },
  {
    keyword: 'priorDataError',
    valueType: 2,
    fieldName: 'priorDataError',
    default: {
      USCustomary: 5,
      Metric: 0.127,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Data Error Mean',
  },
  {
    keyword: 'priorDataErrorSd',
    valueType: 2,
    fieldName: 'priorDataErrorSd',
    default: {
      USCustomary: 5,
      Metric: 0.127,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Data Error SD',
  },
  {
    keyword: 'priorMeasErrorMax',
    valueType: 2,
    fieldName: 'priorMeasErrorMax',
    default: {
      USCustomary: 30,
      Metric: 0.75,
    },
    min: {
      USCustomary: 0.01,
      Metric: 0.00254,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Measurement Error SD',
  },
  {
    keyword: 'priorMeasErrorMin',
    valueType: 2,
    fieldName: 'priorMeasErrorMin',
    default: {
      USCustomary: 1,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Measurement Error Min',
  },
  {
    keyword: 'priorDataErrorMin',
    valueType: 2,
    fieldName: 'priorDataErrorMin',
    default: {
      USCustomary: 0.1,
      Metric: 0.0254,
    },
    min: {
      USCustomary: 0,
      Metric: 0,
    },
    initializer: undefined,
    units: {
      USCustomary: 'mil',
      Metric: 'mm',
    },
    type: 'input',
    label: 'Prior Data Error Min (Sigma)',
  },
  {
    keyword: 'outData',
    valueType: 0,
    fieldName: 'outData',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Output Data',
  },
  {
    keyword: 'analysisDate',
    valueType: 0,
    fieldName: 'analysisDate',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Analysis Date',
  },
  {
    keyword: 'summaryDataRowHeaders',
    valueType: 0,
    fieldName: 'summaryDataRowHeaders',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Summary Data Row Headers',
  },
  {
    keyword: 'finalOutData',
    valueType: 0,
    fieldName: 'finalOutData',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Final Output Data',
  },
  {
    keyword: 'runningMeanOutData',
    valueType: 0,
    fieldName: 'runningMeanOutData',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Running Mean Output Data',
  },
  {
    keyword: 'metalLossProjections',
    valueType: 0,
    fieldName: 'metalLossProjections',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Metal Loss Projections',
  },
  {
    keyword: 'thicknessProjections',
    valueType: 0,
    fieldName: 'thicknessProjections',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Thickness Projections',
  },
  {
    keyword: 'gelmanRubinData',
    valueType: 0,
    fieldName: 'gelmanRubinData',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Gelman Rubin Data',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'gelmanRubinKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'autoCorrelationData',
    valueType: 0,
    fieldName: 'autoCorrelationData',
    default: undefined,
    initializer: undefined,
    type: 'output',
    label: 'Auto Correlation Data',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'autoCorrelationKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'avgDensityKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'densityKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'traceKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'runningMeanKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'thicknessKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'acfPageKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'metalLossKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'pofKeys',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'residualMedians',
    valueType: 2,
    fieldName: 'residualMedians',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'residualLowerBounds',
    valueType: 2,
    fieldName: 'residualLowerBounds',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'residualUpperBounds',
    valueType: 2,
    fieldName: 'residualUpperBounds',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'waicData',
    valueType: 2,
    fieldName: 'waicData',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'looData',
    valueType: 2,
    fieldName: 'looData',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'waicLooLabels',
    valueType: 2,
    fieldName: 'waicLooLabels',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'orderedCmls',
    valueType: 0,
    fieldName: 'orderedCmls',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: 'orderedAcfs',
    valueType: 0,
    fieldName: 'orderedAcfs',
    initializer: undefined,
    type: 'output',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'thicknessPlotsGraphObject',
    initializer: undefined,
    type: 'output',
    label: 'Thickness Plots Graph Object',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'metalLossPlotsGraphObject',
    initializer: undefined,
    type: 'output',
    label: 'Metal Loss Plots Graph Object',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'residualPlotsGraphObject',
    initializer: undefined,
    type: 'output',
    label: 'Residual Plots Graph Object',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'pofPlotsGraphObject',
    initializer: undefined,
    type: 'output',
    label: 'POF Plots Graph Object',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'thicknessPlotsReportFlag',
    default: true,
    transform: (value) => {
      if(value === true) {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
    initializer: undefined,
    type: 'input',
    label: 'Thickness Plots Report Flag',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'metalLossPlotsReportFlag',
    default: true,
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
    initializer: undefined,
    type: 'input',
    label: 'Metal Loss Plots Report Flag',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'pofPlotsReportFlag',
    default: true,
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
    initializer: undefined,
    type: 'input',
    label: 'POF Plots Report Flag',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'densityPlotsReportFlag',
    default: true,
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
    initializer: undefined,
    type: 'input',
    label: 'Density Plots Report Flag',
  },
  {
    keyword: undefined,
    valueType: 0,
    fieldName: 'residualPlotsReportFlag',
    default: true,
    transform: (value) => {
      if(value === true || value === 'true') {
        return 1
      }
      return 0
    },
    inverseTransform: (value) => {
      if(value === 1) {
        return true
      }
      return false
    },
    initializer: undefined,
    type: 'input',
    label: 'Residual Plots Report Flag',
  },
  {
    keyword: 'cmlErrPages',
    valueType: 0,
    fieldName: 'cmlErrPages',
    initializer: undefined,
    type: 'output',
    label: 'CML Err Pages',
    tooltip: 'CML Err Pages',
  },
  {
    keyword: 'cmlErrLabels',
    valueType: 0,
    fieldName: 'cmlErrLabels',
    initializer: undefined,
    type: 'output',
    label: 'CML Err Labels',
    tooltip: 'CML Err Labels',
  },
  {
    keyword: 'acfErrPages',
    valueType: 0,
    fieldName: 'acfErrPages',
    initializer: undefined,
    type: 'output',
    label: 'ACF Err Pages',
    tooltip: 'ACF Err Pages',
  },
  {
    keyword: 'acfErrLabels',
    valueType: 0,
    fieldName: 'acfErrLabels',
    initializer: undefined,
    type: 'output',
    label: 'ACF Err Labels',
    tooltip: 'ACF Err Labels',
  },
  {
    keyword: 'htmlOutFile',
    valueType: 0,
    fieldName: 'htmlOutFile',
    initializer: undefined,
    type: 'output',
    label: 'HTML Results',
    tooltip: 'HTML Results',
  },
  {
    keyword: 'jsonOutputData',
    valueType: 0,
    fieldName: 'jsonOutputData',
    initializer: undefined,
    type: 'output',
    label: 'JSON CR Results',
    tooltip: 'JSON CR Results',
  },
  {
    keyword: 'htmlOutFileDummy',
    valueType: 0,
    fieldName: 'htmlOutFileDummy',
    default: '<html><head>HEADER</head><div>This is the HTML Report using divs and what not</div></html>',
    initializer: undefined,
    type: 'input',
  },
  {
    keyword: 'epRate',
    valueType: 0,
    fieldName: 'epRate',
    initializer: undefined,
    type: 'output',
    label: 'Raw Corrosion Rates',
    tooltip: 'Raw Corrosion Rates',
  },
  {
    keyword: 'acfCrPages',
    valueType: 0,
    fieldName: 'acfCrPages',
    initializer: undefined,
    type: 'output',
    label: 'Data for ACF Cr Box Whiskers',
    tooltip: 'Data for ACF Cr Box Whiskers',
  },
  {
    keyword: 'acfCrLabels',
    valueType: 0,
    fieldName: 'acfCrLabels',
    initializer: undefined,
    type: 'output',
    label: 'Labels for ACF Cr Box Whiskers',
    tooltip: 'Labels for ACF Cr Box Whiskers',
  },
  {
    keyword: 'cmlCrPages',
    valueType: 0,
    fieldName: 'cmlCrPages',
    initializer: undefined,
    type: 'output',
    label: 'Data for CML Cr Box Whiskers',
    tooltip: 'Data for CML Cr Box Whiskers',
  },
  {
    keyword: 'cmlCrLabels',
    valueType: 0,
    fieldName: 'cmlCrLabels',
    initializer: undefined,
    type: 'output',
    label: 'Labels for CML Cr Box Whiskers',
    tooltip: 'Labels for CML Cr Box Whiskers',
  },
  {
    keyword: 'cmlCrColors',
    valueType: 0,
    fieldName: 'cmlCrColors',
    initializer: undefined,
    type: 'output',
    label: 'Colors for CML Cr Box Whiskers',
    tooltip: 'Colors for CML Cr Box Whiskers',
  },
  {
    keyword: 'acfCrColors',
    valueType: 0,
    fieldName: 'acfCrColors',
    initializer: undefined,
    type: 'output',
    label: 'Colors for ACF Cr Box Whiskers',
    tooltip: 'Colors for ACFL Cr Box Whiskers',
  },
  {
    keyword: 'acfLegendColors',
    valueType: 0,
    fieldName: 'acfLegendColors',
    initializer: undefined,
    type: 'output',
    label: 'Colors for ACF Legend on CML Page',
    tooltip: 'Colors for ACF Legend on CML Page',
  },
  {
    keyword: 'acfLegendNames',
    valueType: 0,
    fieldName: 'acfLegendNames',
    initializer: undefined,
    type: 'output',
    label: 'Names for ACF Legend on CML Page',
    tooltip: 'Names for ACF Legend on CML Page',
  },
  {
    keyword: 'riskMatrixJson',
    valueType: 0,
    fieldName: 'riskMatrixJson',
    initializer: undefined,
    type: 'output',
    label: 'Risk Matrix Plot Traces and Layout',
    tooltip: 'Risk Matrix Plot Traces and Layout',
  },
  {
    keyword: 'reinspectionPlotJson',
    valueType: 0,
    fieldName: 'reinspectionPlotJson',
    initializer: undefined,
    type: 'output',
    label: 'ReInspection Plot Traces and Layout',
    tooltip: 'ReInspection Plot Traces and Layout',
  },
  {
    keyword: 'tlSampleSize',
    valueType: 0,
    fieldName: 'tlSampleSize',
    initializer: undefined,
    type: 'output',
    label: 'Number of Samples Analyzed',
    tooltip: 'Number of Samples Analyzed',
  },
  {
    keyword: 'tlCountFails',
    valueType: 0,
    fieldName: 'tlCountFails',
    initializer: undefined,
    type: 'output',
    label: 'Count of the number of failures',
    tooltip: 'Count of the number of failures',
  },
  {
    keyword: 'tlCircuitDesign',
    valueType: 0,
    fieldName: 'tlCircuitDesign',
    initializer: undefined,
    type: 'output',
    label: 'Circuit Design',
    tooltip: 'Circuit Design',
  },
  {
    keyword: 'tlCircuitDesignColor',
    valueType: 0,
    fieldName: 'tlCircuitDesignColor',
    initializer: undefined,
    type: 'output',
    label: 'Circuit Design Status',
    tooltip: 'Circuit Design Status',
  },
  {
    keyword: 'tlCorrosionType',
    valueType: 0,
    fieldName: 'tlCorrosionType',
    initializer: undefined,
    type: 'output',
    label: 'Corrosion Type',
    tooltip: 'Corrsoion Type',
  },
  {
    keyword: 'tlCorrosionTypeColor',
    valueType: 0,
    fieldName: 'tlCorrosionTypeColor',
    initializer: undefined,
    type: 'output',
    label: 'Corrosion Type Status Color',
    tooltip: 'Corrosion Type Status Color',
  },
  {
    keyword: 'tlDataValidation',
    valueType: 0,
    fieldName: 'tlDataValidation',
    initializer: undefined,
    type: 'output',
    label: 'Data Validation',
    tooltip: 'Data Validation',
  },
  {
    keyword: 'tlDataValidationColor',
    valueType: 0,
    fieldName: 'tlDataValidationColor',
    initializer: undefined,
    type: 'output',
    label: 'Data Validation Color',
    tooltip: 'Data Validation Color',
  },
  {
    keyword: 'tlRisks',
    valueType: 0,
    fieldName: 'tlRisks',
    initializer: undefined,
    type: 'output',
    label: 'Risks',
    tooltip: 'Risks',
  },
  {
    keyword: 'tlRisksColor',
    valueType: 0,
    fieldName: 'tlRisksColor',
    initializer: undefined,
    type: 'output',
    label: 'Risks Color',
    tooltip: 'RIsks Color',
  },
  {
    keyword: 'tlCmlQuantity',
    valueType: 0,
    fieldName: 'tlCmlQuantity',
    initializer: undefined,
    type: 'output',
    label: 'Cml Quantity',
    tooltip: 'Cml Quantity',
  },
  {
    keyword: 'tlCmlQuantityColor',
    valueType: 0,
    fieldName: 'tlCmlQuantityColor',
    initializer: undefined,
    type: 'output',
    label: 'Cml Quantity Color',
    tooltip: 'Cml Quantity Color',
  },
  {
    keyword: 'tlCorrosionRates',
    valueType: 0,
    fieldName: 'tlCorrosionRates',
    initializer: undefined,
    type: 'output',
    label: 'Corrosion Rates',
    tooltip: 'Corrosion Rates',
  },
  {
    keyword: 'tlCorrosionRatesColor',
    valueType: 0,
    fieldName: 'tlCorrosionRatesColor',
    initializer: undefined,
    type: 'output',
    label: 'Corrosion Rates Color',
    tooltip: 'Corrosion Rates Color',
  },
  {
    keyword: 'acfPlotJson',
    valueType: 0,
    fieldName: 'acfPlotJson',
    initializer: undefined,
    type: 'output',
    label: 'ACF Priors/Posteriors Plot Traces and Layout',
    tooltip: 'ACF Priors/Posteriors Plot Traces and Layout',
  },
  {
    keyword: 'residualPlotJson',
    valueType: 0,
    fieldName: 'residualPlotJson',
    initializer: undefined,
    type: 'output',
    label: 'Residual Plot Traces and Layout',
    tooltip: 'Residual Plot Traces and Layout',
  },
  {
    keyword: 'cmlPofPlotJson',
    valueType: 0,
    fieldName: 'cmlPofPlotJson',
    initializer: undefined,
    type: 'output',
    label: 'CML POF Plot Traces and Layout',
    tooltip: 'CML POF Plot Traces and Layout',
  },
  {
    keyword: 'crDensityPlotJson',
    valueType: 0,
    fieldName: 'crDensityPlotJson',
    initializer: undefined,
    type: 'output',
    label: 'Corrosion Rate Density Plot Traces and Layout',
    tooltip: 'Corrosion Rate Density Plot Traces and Layout',
  },
]
