/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const TrafficLights = ({ results }) => {
  const tlSampleSize = results.tlSampleSize
  const tlCountFails = results.tlCountFails

  const convergeStatus = tlCountFails > 0 ? 'NOT Converged' : 'Converged'
  const convergeCss = inlineCss(tlCountFails > 0 ? 'red' : 'green')

  const tlCircuitDesign = results.tlCircuitDesign
  const tlCircuitDesignColor = results.tlCircuitDesignColor
  const tlCircuitDesignCss = inlineCss(colorConverter(tlCircuitDesignColor[0]))

  const tlCorrosionTypeColor = results.tlCorrosionTypeColor
  const tlCorrosionType = results.tlCorrosionType
  const tlCorrosionTypeCss = inlineCss(colorConverter(tlCorrosionTypeColor[0]))

  const tlDataValidationColor = results.tlDataValidationColor
  const tlDataValidation = results.tlDataValidation
  const tlDataValidationCss = inlineCss(colorConverter(tlDataValidationColor[0]))

  const tlRisksColor = results.tlRisksColor
  const tlRisks = results.tlRisks
  const tlRisksCss = inlineCss(colorConverter(tlRisksColor[0]))

  const tlCmlQuantityColor = results.tlCmlQuantityColor
  const tlCmlQuantity = results.tlCmlQuantity
  const tlCmlQuantityCss = inlineCss(colorConverter(tlCmlQuantityColor[0]))

  const tlCorrosionRatesColor = results.tlCorrosionRatesColor
  const tlCorrosionRates = results.tlCorrosionRates
  const tlCorrosionRatesCss = inlineCss(colorConverter(tlCorrosionRatesColor[0]))

  return (
    <div className="fl w-100">
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '85%' }} />
        </colgroup>
        <thead>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black', backgroundColor: 'lightgray' }}>
            <th></th>
            <th style={{ textAlign: 'left' }}>Item</th>
            <th style={{ textAlign: 'left' }}>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = {convergeCss}></td>
            <td style={{ verticalAlign: 'top' }}><b>Convergence</b></td>
            <td>{convergeStatus} @ Sample Size = {tlSampleSize}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlCircuitDesignCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>Circuit Design</b></td>
            <td>{tlCircuitDesign}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlCorrosionTypeCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>Uniform/Local</b></td>
            <td>{tlCorrosionType}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlDataValidationCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>Anomalies</b></td>
            <td>{tlDataValidation}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlRisksCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>Risks</b></td>
            <td>{tlRisks}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlCmlQuantityCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>CML Quantity</b></td>
            <td>{tlCmlQuantity}</td>
          </tr>
          <tr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }}>
            <td style = { tlCorrosionRatesCss }></td>
            <td style={{ verticalAlign: 'top' }}><b>Est./Act. Rates</b></td>
            <td>Refer to the Group Prior/Posteriors</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

TrafficLights.propTypes = {
  results: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
})

export default connect(mapStateToProps, null)(TrafficLights)

function inlineCss(thisColor) {
  const css = { backgroundColor: thisColor, width: '25px', height: '25px', borderRadius: '50%', display: 'inline-block', padding: '15px' }
  return css
}

function colorConverter(colorCode) {
  let color = 'gray'

  if(colorCode === 'G') {
    color = 'green'
  } else if(colorCode === 'Y') {
    color = 'yellow'
  } else if(colorCode === 'R') {
    color = 'red'
  }

  return color
}
