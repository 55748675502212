import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getPlotlyTracesLayout } from '../lib/getResultCases.js'

const InspectionPlan = ({ results, inspPlotJson, inspSummary, detailedInspPlan}) => {
  const inspPlotData = getPlotlyTracesLayout(inspPlotJson)

  return (
    <div className="cf w-100">
      {results.reinspectionPlotJson
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={inspPlotData.traces} layout={inspPlotData.layout} />
            </div>
            <div>
              <h2>INSPECTION SUMMARY BY YEAR</h2>
              <DataTableDisplay id="inspSummary" table={inspSummary} colHeaders={{ USCustomary: ['Insp Year', '#CMLs Due'], Metric: ['Insp Year', '#CMLs Due'] }} containerHeight="75px" />
              <h2>CML INSPECTION DETAILS</h2>
              <DataTableDisplay id="detailedInspPlan" table={detailedInspPlan} colHeaders={{ USCustomary: ['Circuit', 'Line/Iso', 'CML ID', 'Database Order', 'Order', 'NPS', 'Component', 'Insp Date', 'POF', 'Method', 'Basis'], Metric: ['Circuit', 'Line/Iso', 'CML ID', 'Database Order', 'Order', 'NPS', 'Component', 'Insp Date', 'POF', 'Method', 'Basis'] }} containerHeight="300px" />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

InspectionPlan.propTypes = {
  results: PropTypes.object,
  inspPlotJson: PropTypes.string,
  inspSummary: PropTypes.object,
  detailedInspPlan: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  inspPlotJson: state.workflow.response ? state.workflow.response.results.reinspectionPlotJson : undefined,
  inspSummary: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.inspSummary : undefined,
  detailedInspPlan: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.detailedInspPlan : undefined,
})

export default connect(mapStateToProps, null)(InspectionPlan)
