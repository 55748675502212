import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'

import { getPlotlyTracesLayout } from '../lib/getResultCases.js'

const CmlPof = ({ results, cmlPofPlotJson }) => {
  const cmlPofPlotData = getPlotlyTracesLayout(cmlPofPlotJson)

  return (
    <div className="cf w-100">
      {results.cmlPofPlotJson
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={cmlPofPlotData.traces} layout={cmlPofPlotData.layout} />
            </div>
            <div>
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

CmlPof.propTypes = {
  results: PropTypes.object,
  cmlPofPlotJson: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  cmlPofPlotJson: state.workflow.response ? state.workflow.response.results.cmlPofPlotJson : undefined,
})

export default connect(mapStateToProps, null)(CmlPof)
